'use client';

import { Text } from '@chakra-ui/react';
import { Modal } from '@/components/Modal';

type BulkUnlinkModalProps = {
  name: string;
  isOpen: boolean;
  toggle: (b: boolean) => void;
  unlink: () => void;
};

export function BulkUnlinkModal({
  name,
  isOpen,
  toggle,
  unlink,
}: BulkUnlinkModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => toggle(false)}
      showCloseButton={false}
      title={`${name}との連携解除は、店舗ごとではなく全店舗一括です`}
      buttons={{
        primary: { label: '一括解除', onClick: () => unlink() },
        secondary: { onClick: () => toggle(false) },
      }}
    >
      <Text>{name}の仕様上、各店舗ごとに連携を解除することはできません。</Text>
      <Text>再連携はいつでも可能です。</Text>
    </Modal>
  );
}

import { AssetItemDialog } from './AssetItemDialog';
import { AssetItemsDialog } from './AssetItemsDialog';
import { AssetItemView } from './AssetItemView';
import { EditAssetItemsDialog, type NewItemData } from './EditAssetItemsDialog';
import { AddLogoDialog } from './AddLogoDialog';
import { Modal } from '@/components/Modal';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { useState, useMemo, useCallback } from 'react';
import { MediaCategories, helpText } from '@/assets/i18n/ja';
import {
  GbpMedia,
  GbpMediaCategory,
  MediaItem,
  UpdateGbpBody,
} from '@/lib/api/schema';
import { useFormContext, useWatch } from 'react-hook-form';
import { format } from 'date-fns';
import { HelpText } from '@/types/store';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';
import { useAtomValue } from 'jotai';

export type MediaList = {
  label: string;
  mainCategory: string;
  mediaItems: MediaLabelData[];
};

export type MediaLabelData = MediaItem & {
  category: string;
  displayUrl: string;
};

export type ImageUrl = {
  originalUrl: string;
  displayUrl: string;
};

type AssetItemsPanelProps = {
  onSetHelpText: (value?: HelpText) => void;
};

export function AssetItemsPanel({ onSetHelpText }: AssetItemsPanelProps) {
  const [targetAssetItem, setTargetAssetItem] = useState<MediaLabelData | null>(
    null,
  );
  const [deleteAssetItem, setDeleteAssetItem] = useState<MediaLabelData | null>(
    null,
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);
  const { setValue } = useFormContext<UpdateGbpBody>();
  const medias: GbpMedia[] | null = useWatch({
    name: 'medias',
  });
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  const mediaList: MediaList[] = useMemo(
    () =>
      MediaCategories.map((item) => {
        const arr = medias
          ? medias.map((media) =>
              item.categories.includes(media.category)
                ? media.mediaItems
                  ? media.mediaItems.map((obj) => {
                      const url = obj.id
                        ? obj.mediaUrl
                        : imageUrls.find((v) => v.originalUrl === obj.mediaUrl)
                            ?.displayUrl;
                      return {
                        ...obj,
                        category: media.category,
                        displayUrl: url || '',
                      };
                    })
                  : []
                : [],
            )
          : [];
        const mediaItems = arr.reduce((acc, curr) => acc.concat(curr), []);
        return {
          label: item.label,
          mainCategory: item.categories[0],
          mediaItems: mediaItems.filter((item) => !item.isDeleted),
        };
      }),
    [imageUrls, medias],
  );

  const deleteItem = useCallback(
    (category: string, mediaUrl: string) => {
      const data = medias ? [...medias] : [];
      const newData = data.map((item) => {
        if (item.category === category) {
          return {
            ...item,
            mediaItems: item.mediaItems
              ? item.mediaItems.reduce((acc, media) => {
                  if (media.mediaUrl === mediaUrl) {
                    media.id && acc.push({ ...media, isDeleted: true });
                  } else {
                    acc.push(media);
                  }
                  return acc;
                }, [] as MediaItem[])
              : null,
          };
        } else {
          return item;
        }
      });
      setValue('medias', newData, { shouldDirty: true });
      setIsDeleteDialogOpen(false);
      setIsViewDialogOpen(false);
    },
    [medias, setValue],
  );

  const addItems = useCallback(
    (value: NewItemData) => {
      const data = medias ? [...medias] : [];
      const newData = data.map((item) => {
        if (item.category === value.category) {
          const newItems: MediaItem[] = value.fileItems.map((v) => ({
            id: null,
            mediaFormat: v.type.startsWith('video/') ? 'VIDEO' : 'PHOTO',
            isDeleted: false,
            mediaUrl: v.originalUrl as string,
            thumbnailUrl: v.originalUrl as string,
            createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          }));
          return {
            ...item,
            mediaItems: item.mediaItems
              ? [...newItems, ...item.mediaItems]
              : newItems,
          };
        } else {
          return item;
        }
      });
      setValue('medias', newData, { shouldDirty: true });
      setImageUrls((prev) => [
        ...prev,
        ...value.fileItems.map((item) => ({
          originalUrl: item.originalUrl as string,
          displayUrl: item.displayUrl,
        })),
      ]);
    },
    [medias, setValue],
  );

  return (
    <>
      <a id="assets" />
      {mediaList.map(({ label, mainCategory, mediaItems }) => {
        // ユーザー提供が空の時は何も表示しない
        if (label === 'ユーザー提供' && mediaItems.length === 0) {
          return null;
        }
        return (
          <Box
            key={label}
            onMouseEnter={() => {
              onSetHelpText(
                helpText.google.assetItems[mainCategory as GbpMediaCategory],
              );
            }}
            onMouseLeave={() => onSetHelpText(undefined)}
          >
            <Flex align="center" py={2}>
              <Text fontWeight={700}>{label}</Text>
              <Spacer />
              {mediaItems.length > 7 && (
                <AssetItemsDialog
                  label={label}
                  items={mediaItems}
                  onClick={(item) => {
                    setTargetAssetItem(item);
                    setIsViewDialogOpen(true);
                  }}
                  onDeleteClick={(item) => {
                    setDeleteAssetItem(item);
                    setIsDeleteDialogOpen(true);
                  }}
                />
              )}
            </Flex>
            <Flex gap={2} wrap="wrap" pb={2}>
              {label === 'カバー' &&
                mediaItems.length === 0 &&
                isStoreInfoEdit && (
                  <AddLogoDialog category={mainCategory} onSubmit={addItems} />
                )}
              {label === 'ロゴ' &&
                mediaItems.length === 0 &&
                isStoreInfoEdit && (
                  <AddLogoDialog category={mainCategory} onSubmit={addItems} />
                )}
              {label !== 'ロゴ' &&
                label !== 'カバー' &&
                label !== 'ユーザー提供' &&
                isStoreInfoEdit && (
                  <EditAssetItemsDialog
                    category={mainCategory}
                    onSubmit={addItems}
                  />
                )}
              {mediaItems?.slice(0, 7).map((item, i) => (
                <AssetItemView
                  key={item.id || i}
                  assetItem={{ ...item }}
                  onClick={() => {
                    setTargetAssetItem({
                      ...item,
                    });
                    setIsViewDialogOpen(true);
                  }}
                  onDeleteClick={() => {
                    setDeleteAssetItem({
                      ...item,
                    });
                    setIsDeleteDialogOpen(true);
                  }}
                />
              ))}
            </Flex>
          </Box>
        );
      })}

      {targetAssetItem && (
        <AssetItemDialog
          isOpen={isViewDialogOpen}
          assetItem={targetAssetItem!}
          onClose={() => setIsViewDialogOpen(false)}
          onNegativeClick={() => {
            setDeleteAssetItem(
              targetAssetItem
                ? {
                    ...targetAssetItem,
                  }
                : null,
            );
            setIsDeleteDialogOpen(true);
          }}
        />
      )}

      <Modal
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        title="写真・動画を削除"
        message="写真・動画を完全に削除しますか？この操作は元に戻せません。"
        buttons={{
          secondary: { onClick: () => setIsDeleteDialogOpen(false) },
          danger: {
            onClick: () =>
              deleteAssetItem &&
              deleteItem(deleteAssetItem.category, deleteAssetItem.mediaUrl),
          },
        }}
      />
    </>
  );
}

import { atom } from 'jotai';
import { abilityAtom } from '@/lib/atoms/casl';

export const storeAbilitiesAtom = atom((get) => {
  const ability = get(abilityAtom);

  return {
    isStoreInfoEdit: ability
      ? ability?.can('store.information', 'edit')
      : false,
    isStoreInfoDelete: ability
      ? ability?.can('store.information', 'delete')
      : false,
    isStoreInfoLink: ability
      ? ability?.can('store.information', 'link')
      : false,
    isStoreListTamperProtection: ability
      ? ability?.can('store.list.tamper_protection', 'enable')
      : false,
    isStoreListBulkDownload: ability
      ? ability?.can('store.list.bulk', 'download')
      : false,
    isStoreListBulkLink: ability
      ? ability?.can('store.list.bulk', 'link')
      : false,
    isStoreListBulkEdit: ability
      ? ability?.can('store.list.bulk', 'edit')
      : false,
    isStoreListBulkCreate: ability
      ? ability?.can('store.list.bulk', 'create')
      : false,
  };
});

import { Labels } from '@/assets/i18n/ja';
import { Modal } from '@/components/Modal';

type PageTransitionDialogProps = {
  isOpen: boolean;
  isValid?: boolean;
  onClose: () => void;
  onSave: () => void;
  onNoSave: () => void;
};

export function PageTransitionDialog({
  isOpen,
  isValid = true,
  onClose,
  onSave,
  onNoSave,
}: PageTransitionDialogProps) {
  return (
    <Modal
      title="変更内容を保存していません"
      message="ページを切り替える前に、変更内容を保存しますか？"
      isOpen={isOpen}
      onClose={onClose}
      buttons={{
        primary: {
          label: Labels.button.save,
          isDisabled: !isValid,
          onClick: onSave,
        },
        secondary: {
          label: Labels.button.noSave,
          onClick: onNoSave,
        },
        text: {
          label: Labels.button.backToEdit,
          onClick: onClose,
        },
      }}
    />
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type SortPostByParameter = typeof SortPostByParameter[keyof typeof SortPostByParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortPostByParameter = {
  UPDATED_AT: 'UPDATED_AT',
} as const;

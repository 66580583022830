'use client';

import { useEffect, useState } from 'react';
import {
  CheckboxGroup,
  Checkbox,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { FilterButton } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { roles } from '@/constants/roles';
import { UserRole } from '@/types/user';

type RoleDialogProps = {
  values: UserRole[];
  onSubmit: (v: UserRole[]) => void;
};

export function RoleDialog({ values, onSubmit }: RoleDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>(values || []);
  useEffect(() => {
    setSelectedRoles(values);
  }, [values]);

  // TODO: ユーザー自身のロールに応じて選択可能なロールを絞り込む
  const selectableRoles = roles.filter((role) => role.name !== 'administrator');

  function handleCheckboxChange(values: (string | number)[]) {
    const newSelectedRoles = selectableRoles.filter((r) =>
      values.includes(r.name),
    );
    setSelectedRoles(newSelectedRoles);
  }

  function handleSubmit() {
    onSubmit(selectedRoles);
    onClose();
  }

  function handleClose() {
    setSelectedRoles(values || []);
    onClose();
  }

  return (
    <>
      <FilterButton
        label="権限"
        isFilled={values.length > 0}
        width="auto"
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        title="権限で絞り込み"
        buttons={{
          primary: { onClick: handleSubmit },
          secondary: { onClick: handleClose },
        }}
      >
        <Stack>
          <CheckboxGroup
            defaultValue={selectedRoles.map((r) => r.name)}
            onChange={handleCheckboxChange}
          >
            {selectableRoles.map((role) => (
              <Checkbox key={role.name} value={role.name}>
                {role.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Stack>
      </Modal>
    </>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 投稿ステータス
 */
export type PostStatusParameter = typeof PostStatusParameter[keyof typeof PostStatusParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostStatusParameter = {
  PUBLISHED: 'PUBLISHED',
  SCHEDULED: 'SCHEDULED',
  APPROVAL: 'APPROVAL',
  ERROR: 'ERROR',
} as const;

'use client';

import { getStyles } from '@/components/Select';
import CreatableSelect from 'react-select/creatable';
import { GroupBase } from 'react-select';
import ArrowDownSvg from '@/assets/icon/ArrowDown.svg';
import { Center } from '@chakra-ui/react';

type SelectOption = {
  label: string;
  value: string;
};

type SelectTimeProps = {
  size?: 'md' | 'lg';
  isDisabled?: boolean;
  stepMin?: 1 | 5 | 10 | 15 | 30;
  placeholder?: string;
  value?: string | null;
  defaultValue?: string | null;
  onChange: (val: string | null) => void;
};

export function SelectTime({
  size = 'lg',
  isDisabled,
  stepMin = 15,
  placeholder,
  value,
  defaultValue,
  onChange,
}: SelectTimeProps) {
  // TODO: react v19 出たらメモ化してネ！
  const count = 60 / stepMin;
  const mins = Array.from({ length: count }, (_, i) =>
    `${i * stepMin}`.padStart(2, '0'),
  );
  const times = Array.from({ length: 24 }, (_, i) => {
    const hh = `${i}`.padStart(2, '0');
    return mins.map((mm) => ({ value: `${hh}:${mm}`, label: `${hh}:${mm}` }));
  }).flat();

  const isHhmmFormat = new RegExp(/^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/);

  return (
    <CreatableSelect<SelectOption, false, GroupBase<SelectOption>>
      isMulti={false}
      isDisabled={isDisabled}
      menuPlacement="auto"
      styles={getStyles<SelectOption>({ size, flexGrow: 'auto' })}
      placeholder={placeholder}
      options={times}
      value={!value ? null : { value, label: value }}
      defaultValue={
        !defaultValue ? null : { value: defaultValue, label: defaultValue }
      }
      createOptionPosition="first"
      formatCreateLabel={(inputValue: string) =>
        isHhmmFormat.test(inputValue) ? inputValue : null
      }
      onChange={(val) => {
        const tmp = val ? (val.value as string) : '';
        onChange(isHhmmFormat.test(tmp) ? tmp : null);
      }}
      menuPortalTarget={document.body}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: () => (
          <Center w={8}>
            <ArrowDownSvg />
          </Center>
        ),
      }}
      // isSearchable
      // filterOption={(opt, txt) => opt.label.startsWith(txt)}
    />
  );
}

'use client';

import { Modal } from '@/components/Modal';

type SaveModalProps = {
  isOpen: boolean;
  toggle: (b: boolean) => void;
  save: () => void;
  discard: () => void;
};

export function SaveModal({ isOpen, save, discard, toggle }: SaveModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => toggle(false)}
      title="変更内容の保存"
      message="変更内容が保存されていません。 ページを切り替える前に、変更内容を保存しますか？"
      buttons={{
        primary: { label: '保存', onClick: () => save() },
        secondary: { label: '変更を破棄', onClick: () => discard() },
        text: { label: '編集に戻る', onClick: () => toggle(false) },
      }}
    />
  );
}

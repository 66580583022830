import { Icon } from '@/components/Icon';
import { PostMedia } from '@/types/post';
import { Box, Button, Center, Image } from '@chakra-ui/react';
import { useState } from 'react';

type PostAssetItemViewProps = {
  assetItem: PostMedia;
  onClick?: () => void;
  onDeleteClick: (item: PostMedia) => void;
};

export function PostAssetItemView({
  assetItem,
  onClick,
  onDeleteClick,
}: PostAssetItemViewProps) {
  const [hover, setHover] = useState(false);

  return (
    <Box
      pos="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Image
        src={assetItem.url}
        alt="img"
        w={144}
        h={144}
        borderRadius={4}
        objectFit="cover"
        cursor="pointer"
        transition={'filter 0.2s ease'}
        filter={hover ? 'brightness(70%)' : 'none'}
        onClick={onClick}
      />
      {assetItem.type === 'video' && (
        <Center
          pos="absolute"
          top="48px"
          left="48px"
          borderRadius={4}
          zIndex={5}
        >
          <Icon name="video" size="xl" color="white" />
        </Center>
      )}
      {hover && (
        <Button
          variant="negative"
          pos="absolute"
          top={2}
          right={2}
          onClick={() => onDeleteClick(assetItem)}
        >
          削除
        </Button>
      )}
    </Box>
  );
}

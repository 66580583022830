import {
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

type PostAssetItemDialogProps = {
  isOpen: boolean;
  url: string;
  isPhotoOnly?: boolean;
  onClose: () => void;
  onNegativeClick: () => void;
};

export function PostAssetItemDialog({
  isOpen,
  url,
  isPhotoOnly = false,
  onClose,
  onNegativeClick,
}: PostAssetItemDialogProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isPhotoOnly ? '写真' : '写真・動画'}のプレビュー
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH={'362px'}>
          <Center height={'330px'} width={'652px'}>
            <Image
              src={url}
              alt="preview"
              objectFit={'contain'}
              height={'100%'}
            />
          </Center>
        </ModalBody>
        <ModalFooter as={Flex} gap={3} align="center">
          <Button variant="negative" onClick={onNegativeClick}>
            削除
          </Button>
          <Button onClick={onClose}>閉じる</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

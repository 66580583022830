/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディアの種類。画像か動画かを指定 (image, video)
 */
export type CreateInstagramPostBodyMediaItemType = typeof CreateInstagramPostBodyMediaItemType[keyof typeof CreateInstagramPostBodyMediaItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateInstagramPostBodyMediaItemType = {
  image: 'image',
  video: 'video',
} as const;

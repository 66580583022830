'use client';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { TertiaryButton } from '@/components/Button';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

type ScheduledUpdatesDialogProps = {
  days?: { dateTime: string }[];
};

export function ScheduledUpdatesDialog({
  days = [{ dateTime: '2024.08.01 00:00' }, { dateTime: '2024.08.01 00:00' }],
}: ScheduledUpdatesDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  // useEffect(() => setEditingDays(days || []), [days]);

  return (
    <>
      {isStoreInfoEdit && (
        <TertiaryButton
          label="日付をまとめて追加"
          iconName="calendar"
          onClick={onOpen}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>更新予約一覧</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {days.map((el, i) => (
              <Flex
                key={i}
                p={4}
                gap={4}
                align="center"
                borderBottom="1px solid"
                borderBottomColor="gray.500"
              >
                <Text>{el.dateTime}</Text>
                <Button variant="negative">取り消し</Button>
                <Button variant="tertiary">編集</Button>
              </Flex>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

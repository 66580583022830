/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * - administrator: アドミン
- manager: 管理者
- editor: 編集者
- worker_store_edit: 作業者（店舗情報編集可）
- worker: 作業者（店舗情報編集不可）
- viewer: 閲覧者

 */
export type Role = typeof Role[keyof typeof Role];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  administrator: 'administrator',
  manager: 'manager',
  editor: 'editor',
  worker_store_edit: 'worker_store_edit',
  worker: 'worker',
  viewer: 'viewer',
} as const;

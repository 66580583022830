/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 処理ステータス
 */
export type PostGroupPostQueueStatus = typeof PostGroupPostQueueStatus[keyof typeof PostGroupPostQueueStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostGroupPostQueueStatus = {
  UNPROCESSED: 'UNPROCESSED',
  ENQUEUED: 'ENQUEUED',
  PROCESSED: 'PROCESSED',
} as const;

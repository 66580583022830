'use client';

import { DatePicker } from '@/components/DatePicker';
import { ConfirmLink } from '@/components/Link';
import { SelectTime } from '@/components/Select';
import { Box, Button, Flex, Radio, RadioGroup } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import { useState } from 'react';

type ScheduleSubmitFormProps = {
  redirectHref: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  onChangeSchedule: (val: string | null) => void;
  onSubmit: () => void;
};

export function ScheduleSubmitForm({
  redirectHref,
  cancelButtonText,
  submitButtonText,
  isDirty,
  isValid,
  isSubmitting,
  onChangeSchedule,
  onSubmit,
}: ScheduleSubmitFormProps) {
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState<string>('');
  const [scheduleTime, setScheduleTime] = useState<string>('');
  const isScheduleValid =
    !isScheduled || (scheduleDate !== '' && scheduleTime !== '');

  return (
    <>
      <RadioGroup
        display="flex"
        gap={3}
        defaultValue={'now'}
        onChange={(val) => {
          const hasSchedule = val === 'schedule';
          if (hasSchedule) {
            if (scheduleDate && scheduleTime) {
              onChangeSchedule(scheduleDate + ' ' + scheduleTime);
            }
          } else {
            onChangeSchedule(null);
          }
          setIsScheduled(hasSchedule);
        }}
      >
        <Radio value={'now'}>現在時刻で{submitButtonText || '更新'}</Radio>
        <Radio value={'schedule'}>{submitButtonText || '更新'}の予約</Radio>
      </RadioGroup>
      <Flex gap={3} align="center" mx={3}>
        <Box maxW="160px">
          <DatePicker
            disabled={!isScheduled}
            minDate={new Date()}
            value={scheduleDate.replace(/-/g, '.')}
            onChange={(v) => {
              if (v) {
                const date = formatDate(v, 'yyyy-MM-dd');
                setScheduleDate(date);
                if (scheduleTime) {
                  onChangeSchedule(date + ' ' + scheduleTime);
                }
              }
            }}
          />
        </Box>
        <SelectTime
          size="md"
          placeholder="--:--"
          isDisabled={!isScheduled}
          value={scheduleTime}
          onChange={(v) => {
            if (v) {
              setScheduleTime(v);
              if (scheduleDate) {
                onChangeSchedule(scheduleDate + ' ' + v);
              }
            }
          }}
        />
      </Flex>
      <Flex gap={3} align="center">
        <ConfirmLink
          href={redirectHref}
          isDirty={isDirty}
          isValid={isValid && isScheduleValid}
          onSave={onSubmit}
        >
          <Button variant="secondary">
            {cancelButtonText || 'キャンセル'}
          </Button>
        </ConfirmLink>
        <Button
          isDisabled={!(isValid && isScheduleValid)}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          {submitButtonText || '更新'}
        </Button>
      </Flex>
    </>
  );
}

'use client';

import { Modal } from '@/components/Modal';

type RemoveGrantModalProps = {
  mediaName: string;
  isOpen: boolean;
  toggle: (b: boolean) => void;
  remove: () => void;
};

export function RemoveGrantModal({
  mediaName,
  isOpen,
  toggle,
  remove,
}: RemoveGrantModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => toggle(false)}
      title="アクセス権の取り消し"
      message={`連携を再開する場合は、改めて${mediaName}にログインする必要があります。`}
      buttons={{
        primary: { label: '取り消す', onClick: () => remove() },
        secondary: { onClick: () => toggle(false) },
      }}
    />
  );
}

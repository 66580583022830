import { Icon } from '@/components/Icon';
import { Flex, Text, type FlexProps } from '@chakra-ui/react';

export function AddAssetItemButton({ ...rest }: FlexProps) {
  return (
    <Flex
      w={144}
      h={144}
      direction="column"
      align="center"
      justify="center"
      gap={2}
      bg="blue.50"
      borderRadius={4}
      cursor="pointer"
      _hover={{ bg: 'blue.100' }}
      {...rest}
    >
      <Icon name="addPhoto" size="lg" color="blue.500" />
      <Text color="blue.500">さらに追加</Text>
    </Flex>
  );
}

'use client';

import { Text } from '@chakra-ui/react';
import { Modal } from '@/components/Modal';

type UnlinkModalProps = {
  isOpen: boolean;
  toggle: (b: boolean) => void;
  unlink: () => void;
};

export function UnlinkModal({ isOpen, toggle, unlink }: UnlinkModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => toggle(false)}
      title="連携の解除"
      buttons={{
        primary: { label: '解除', onClick: () => unlink() },
        secondary: { onClick: () => toggle(false) },
      }}
    >
      <Text>
        連携を解除すると、カンリーから各媒体へ店舗情報の更新や一括投稿ができなくなります。
      </Text>
      <Text>連携を解除しますか？</Text>
    </Modal>
  );
}

'use client';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

export function CancelUpdateDialog({ dateTime }: { dateTime: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  return (
    <>
      {isStoreInfoEdit && (
        <Button variant="negative" onClick={onOpen}>
          取り消し
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>予約を取り消し</ModalHeader>

          <ModalBody>
            <Text>{dateTime}の更新予約を取り消しますか？</Text>
            <Text>この操作は元に戻せません。</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              キャンセル
            </Button>
            <Button variant="danger" onClick={onClose}>
              取り消し
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

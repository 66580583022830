/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type MediaParameterItem = typeof MediaParameterItem[keyof typeof MediaParameterItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MediaParameterItem = {
  GBP: 'GBP',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  APPLE: 'APPLE',
  YAHOO: 'YAHOO',
} as const;

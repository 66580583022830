'use client';

import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Icon, IconName } from '@/components/Icon';
import { Modal } from '@/components/Modal';
import { useEffect, useState } from 'react';
import { FilterButton } from '@/components/Button';
import { MediaParameterItem } from '@/lib/api/schema';
import { useAtomValue } from 'jotai';
import { hasOptionsAtom } from '@/lib/atoms/option';

export type LinkableMedia = {
  id: MediaParameterItem;
  icon: IconName;
  name: string;
  hasOption: boolean;
};

export type MediaFilter = {
  media: LinkableMedia[];
  isLinked: boolean;
};

type MediaDialogProps = {
  values: MediaFilter;
  onSubmit: (v: MediaFilter) => void;
};

export function MediaDialog({ values, onSubmit }: MediaDialogProps) {
  const {
    hasAppleOption,
    hasGbpOption,
    hasFacebookOption,
    hasInstagramOption,
    hasYahooOption,
  } = useAtomValue(hasOptionsAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMedia, setSelectedMedia] = useState<MediaFilter>(values);
  useEffect(() => {
    setSelectedMedia(values);
  }, [values]);

  function handleRadioChange(value: '0' | '1') {
    setSelectedMedia({
      ...selectedMedia,
      isLinked: value === '1',
    });
  }

  function handleCheckboxChange(values: string[]) {
    setSelectedMedia({
      ...selectedMedia,
      media: linkableMedias.filter((m) => values.includes(m.id)),
    });
  }

  function handleSubmit() {
    onSubmit(selectedMedia);
    onClose();
  }

  function handleClose() {
    setSelectedMedia(values);
    onClose();
  }

  const linkableMedias: LinkableMedia[] = [
    {
      id: MediaParameterItem.GBP,
      icon: 'gbp',
      name: 'Googleビジネスプロフィール',
      hasOption: hasGbpOption ?? false,
    },
    {
      id: MediaParameterItem.APPLE,
      icon: 'apple',
      name: 'Apple Business Connect',
      hasOption: hasAppleOption ?? false,
    },
    {
      id: MediaParameterItem.YAHOO,
      icon: 'yahoo',
      name: 'Yahoo! プレイス',
      hasOption: hasYahooOption ?? false,
    },
    {
      id: MediaParameterItem.FACEBOOK,
      icon: 'facebook',
      name: 'Facebook',
      hasOption: hasFacebookOption ?? false,
    },
    {
      id: MediaParameterItem.INSTAGRAM,
      icon: 'instagram',
      name: 'Instagram',
      hasOption: hasInstagramOption ?? false,
    },
  ];

  return (
    <>
      <FilterButton
        label="連携媒体"
        isFilled={values.media.length > 0}
        width="auto"
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        title="連携媒体で絞り込み"
        buttons={{
          primary: { onClick: handleSubmit },
          secondary: { onClick: handleClose },
        }}
        variant="fixedHeight"
      >
        <Box w="452px" bgColor="blue.50" px={3} py={2}>
          <Flex align="center" gap={2}>
            <Text>以下の媒体が</Text>
            <RadioGroup
              defaultValue={selectedMedia.isLinked ? '1' : '0'}
              onChange={handleRadioChange}
            >
              <Stack direction="row" spacing={3}>
                <Radio value="0">未連携</Radio>
                <Radio value="1">連携中</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Box>
        <Stack mt={6}>
          <CheckboxGroup
            defaultValue={selectedMedia.media.map((m) => m.id)}
            onChange={handleCheckboxChange}
          >
            {linkableMedias.map(
              (m) =>
                m.hasOption && (
                  <Checkbox key={m.id} value={m.id}>
                    <Flex align="center" gap={2}>
                      <Icon
                        name={m.icon}
                        size="md"
                        color={m.id === MediaParameterItem.APPLE ? 'black' : ''}
                      />
                      {m.name}
                    </Flex>
                  </Checkbox>
                ),
            )}
          </CheckboxGroup>
        </Stack>
      </Modal>
    </>
  );
}

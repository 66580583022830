/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 予約リンクタイプ
* PLACE_ACTION_TYPE_UNSPECIFIED（指定されていません。）,
* APPOINTMENT（アクション タイプは予定の予約です。）,
* ONLINE_APPOINTMENT（アクションの種類はオンライン診察の予約です。）,
* DINING_RESERVATION（アクション タイプはレストランの予約です。）,
* FOOD_ORDERING（アクション タイプは料理の宅配またはテイクアウトの注文です。）,
* FOOD_DELIVERY（アクション タイプは料理の宅配の注文です。）,
* FOOD_TAKEOUT（アクション タイプは料理のテイクアウトの注文です。）,
* SHOP_ONLINE（アクション タイプはショッピングで、配送や受け取りが可能です。）
 */
export type GbpPlaceActionLinkPlaceActionType = typeof GbpPlaceActionLinkPlaceActionType[keyof typeof GbpPlaceActionLinkPlaceActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GbpPlaceActionLinkPlaceActionType = {
  PLACE_ACTION_TYPE_UNSPECIFIED: 'PLACE_ACTION_TYPE_UNSPECIFIED',
  APPOINTMENT: 'APPOINTMENT',
  ONLINE_APPOINTMENT: 'ONLINE_APPOINTMENT',
  DINING_RESERVATION: 'DINING_RESERVATION',
  FOOD_ORDERING: 'FOOD_ORDERING',
  FOOD_DELIVERY: 'FOOD_DELIVERY',
  FOOD_TAKEOUT: 'FOOD_TAKEOUT',
  SHOP_ONLINE: 'SHOP_ONLINE',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type UpdateAutoPostPlatformBodyPlatform = typeof UpdateAutoPostPlatformBodyPlatform[keyof typeof UpdateAutoPostPlatformBodyPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateAutoPostPlatformBodyPlatform = {
  GBP: 'GBP',
} as const;

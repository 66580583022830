/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type GetPostsIsMultiStorePost = typeof GetPostsIsMultiStorePost[keyof typeof GetPostsIsMultiStorePost];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPostsIsMultiStorePost = {
  YES: 'YES',
  NO: 'NO',
} as const;

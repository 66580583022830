/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * フィードタイプ。NOTICE(お知らせ) /DAYOFF(営業状況) /CAMPAIGN(キャンペーン) /ETC(その他)
 */
export type CreateYahooPostBodyFeedType = typeof CreateYahooPostBodyFeedType[keyof typeof CreateYahooPostBodyFeedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateYahooPostBodyFeedType = {
  NOTICE: 'NOTICE',
  DAYOFF: 'DAYOFF',
  CAMPAIGN: 'CAMPAIGN',
  ETC: 'ETC',
} as const;

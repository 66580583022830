import ReactSelect, { GroupBase, StylesConfig } from 'react-select';
import { colors, fontSizes } from '@/styles/custom.theme';
import { Center } from '@chakra-ui/react';
import ArrowDownSvg from '@/assets/icon/ArrowDown.svg';
import { Icon } from '@/components/Icon';

export type SelectOption = {
  value: string;
  label: string;
};

type SelectProps = {
  options: SelectOption[];
  isDisabled?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  size?: 'lg' | 'md' | 'sm';
  flexGrow?: number | string;
};

type SelectSingleProps = SelectProps & {
  value?: SelectOption;
  defaultValue?: SelectOption;
  onChange: (newValue: SelectOption) => void;
  onFocus?: () => void;
  onInputChange?: (value: string) => void;
};

type SelectMultiProps = SelectProps & {
  value?: SelectOption[];
  defaultValue?: SelectOption[];
  onChange: (newValue: SelectOption[]) => void;
  onFocus?: () => void;
  onInputChange?: (value: string) => void;
};

type StyleProps = {
  size: 'sm' | 'md' | 'lg';
  flexGrow?: string | number;
};

export function getStyles<T>({ size = 'lg', flexGrow = 'auto' }: StyleProps) {
  const styles: StylesConfig<T> = {
    container: (base) => ({ ...base, flexGrow: flexGrow, minWidth: 99 }),
    control: (base, state) => ({
      ...base,
      height: 'auto',
      minHeight: `${{ sm: 30, md: 36, lg: 40 }[size]}px`,
      background: state.isDisabled ? colors.gray[50] : 'white',
      borderColor: colors.gray[200],
      caretColor: colors.gray[500],
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      fontSize: fontSizes.md,
      pointerEvents: 'auto',
    }),
    placeholder: (base) => ({ ...base, color: colors.gray[300] }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({ ...base, fontSize: fontSizes.md }),
    option: (base, props) => ({
      ...base,
      color: colors.gray[500],
      background:
        props.isSelected || props.isFocused ? colors.blue[50] : 'inherit',
      cursor: 'pointer',
    }),
  };
  return styles;
}

export function Select({
  size = 'md',
  flexGrow,
  onChange,
  onFocus,
  onInputChange,
  ...rest
}: SelectSingleProps) {
  return (
    <ReactSelect
      styles={getStyles({ size, flexGrow })}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: () => (
          <Center w={8}>
            <Icon size="sm" name="arrowDown" color="gray.500" />
          </Center>
        ),
      }}
      menuPlacement="auto"
      onChange={(newValue) => onChange(newValue as SelectOption)}
      onFocus={onFocus}
      onInputChange={onInputChange}
      {...rest}
    />
  );
}

export function SelectMulti({
  size = 'lg',
  flexGrow,
  onChange,
  onFocus,
  onInputChange,
  ...rest
}: SelectMultiProps) {
  return (
    <ReactSelect<SelectOption, true, GroupBase<SelectOption>>
      styles={getStyles({ size, flexGrow })}
      isMulti={true}
      onChange={(newValue) => onChange(newValue as SelectOption[])}
      onFocus={onFocus}
      onInputChange={onInputChange}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: () => (
          <Center w={8}>
            <ArrowDownSvg />
          </Center>
        ),
        // isDisabledがtrueの場合だけ各アイテムの[x]ボタン無効化
        ...(rest.isDisabled && {
          MultiValueRemove: () => null,
        }),
      }}
      {...rest}
    />
  );
}

type IdNameMapperProps = {
  id: number | string;
  name: string;
};

export function idNameMapper({ id, name }: IdNameMapperProps): SelectOption {
  return { value: `${id}`, label: name };
}

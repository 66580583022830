/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * Yahoo!プレイスの画像カテゴリ
* COVER (カバー写真、1枚まで登録可能)
* SECONDARY (製品・サービス)
* INSIDE (内観)
* OUTSIDE (外観)
* ETC (その他)
 */
export type YahooBusinessImageCategory = typeof YahooBusinessImageCategory[keyof typeof YahooBusinessImageCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YahooBusinessImageCategory = {
  COVER: 'COVER',
  SECONDARY: 'SECONDARY',
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
  ETC: 'ETC',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディアのカテゴリ
* CATEGORY_UNSPECIFIED （カテゴリが指定されていません）,
* COVER （カバー写真です。ビジネスに指定できるカバー写真は 1 枚のみです。）,
* PROFILE （プロフィール写真です。ビジネスに指定できるプロフィール写真は 1 枚のみです。）,
* LOGO （ロゴ写真。）,
* EXTERIOR （外観のメディアです。）,
* INTERIOR （内装のメディアです。）,
* PRODUCT （商品のメディアです。）,
* AT_WORK （「勤務中」のメディアです。）,
* FOOD_AND_DRINK （食べ物と飲み物のメディアです。）,
* MENU （メニューのメディアです。）,
* COMMON_AREA （共用エリアのメディアです。）,
* ROOMS （部屋のメディアです。）,
* TEAMS （チームのメディアです。）,
* ADDITIONAL （その他の未分類のメディアです。：カンリーでは「オーナー提供」に表示するカテゴリー）
* CUSTOMERS (ユーザー提供)
 */
export type GbpMediaCategory = typeof GbpMediaCategory[keyof typeof GbpMediaCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GbpMediaCategory = {
  CATEGORY_UNSPECIFIED: 'CATEGORY_UNSPECIFIED',
  COVER: 'COVER',
  PROFILE: 'PROFILE',
  LOGO: 'LOGO',
  EXTERIOR: 'EXTERIOR',
  INTERIOR: 'INTERIOR',
  PRODUCT: 'PRODUCT',
  AT_WORK: 'AT_WORK',
  FOOD_AND_DRINK: 'FOOD_AND_DRINK',
  MENU: 'MENU',
  COMMON_AREA: 'COMMON_AREA',
  ROOMS: 'ROOMS',
  TEAMS: 'TEAMS',
  ADDITIONAL: 'ADDITIONAL',
  CUSTOMERS: 'CUSTOMERS',
} as const;

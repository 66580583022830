'use client';

import { AddAssetItemButton } from '@/components/Button';
import { NewItemData } from '@/components/File/EditAssetItemsDialog';
import { Message } from '@/components/Message';
import { useFileUpload } from '@/hooks/useFileUpload';
import { storeAtom } from '@/lib/atoms/store';
import { removeExifData } from '@/utils/file';
import {
  Button,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useRef, useState, useEffect } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
}

type ImageFile = {
  name: string;
  size: number;
  type: string;
  dataUrl: string;
};

type AddLogoDialogProps = {
  category: string;
  onSubmit: (value: NewItemData) => void;
};

export function AddLogoDialog({ category, onSubmit }: AddLogoDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const [store] = useAtom(storeAtom);
  const [imageFile, setImageFile] = useState<ImageFile | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [areaPixels, setAreaPixels] = useState<Area>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUploading, setIsUploading] = useState(false);
  const { gbpFileUpload } = useFileUpload();

  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  useEffect(() => {
    if (isOpen) {
      setImageFile(null);
      setErrorMessage('');
    }
  }, [isOpen]);

  async function handleFiles(fileList: FileList | null) {
    if (!fileList) return;
    const files = Array.from(fileList);
    setErrorMessage('');
    if (files.length > 1) {
      setErrorMessage('利用できるファイルは1つだけです。');
      return;
    }
    if (!['image/jpeg', 'image/png'].includes(files[0].type)) {
      setErrorMessage(
        'アップロードできないファイル形式があります。以下のファイル形式でアップロードしてください。\n・写真：JPG、PNG',
      );
      return;
    }
    const file = await removeExifData(files[0]);
    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage(
        `ファイルサイズが大きすぎます（${Math.floor(file.size / 1024 / 1024)}MB）。5MB以下のファイルをアップロードしてください。`,
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const { name, size, type } = file;
      const dataUrl = e.target?.result as string;
      const result = await new Promise<boolean>((resolve) => {
        const elm = document.createElement('img');
        elm.src = dataUrl;
        elm.onload = () => {
          if (elm.width < 250 || elm.height < 250) {
            setErrorMessage(
              'ファイルの縦横サイズが小さすぎます。縦250ピクセル・横250ピクセル以上のファイルをアップロードしてください。',
            );
            resolve(false);
          }
          resolve(true);
        };
      });
      result && setImageFile({ name, size, type, dataUrl });
    };
    reader.readAsDataURL(file);
  }

  async function getCroppedImage(): Promise<Blob> {
    if (!imageFile || !areaPixels) throw new Error('not prepared');
    const { dataUrl, type } = imageFile;
    const image = await createImage(dataUrl!);

    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('no context on canvas');
    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(
      areaPixels.x,
      areaPixels.y,
      areaPixels.width,
      areaPixels.height,
    );
    canvas.width = areaPixels.width;
    canvas.height = areaPixels.height;
    ctx.putImageData(data, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file ? resolve(file) : reject('no data from canvas');
      }, type);
    });
  }

  async function handleOk() {
    if (!imageFile) return;
    setIsUploading(true);
    const { type, name } = imageFile;
    const imageBlob = await getCroppedImage();
    const file = new File([imageBlob], name, { type });
    try {
      const originalUrl = await gbpFileUpload(store.id, file);
      if (originalUrl) {
        onSubmit({
          category,
          fileItems: [
            {
              ...imageFile,
              displayUrl: imageFile.dataUrl,
              rowFile: file,
              originalUrl,
            },
          ],
        });
        onClose();
      } else {
        throw new Error('Failed to upload: No originalUrl');
      }
    } catch (error) {
      setErrorMessage(
        'データの保存中にエラーが発生しました。もう一度決定ボタンを押してください。',
      );
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <>
      <AddAssetItemButton onClick={isStoreInfoEdit ? onOpen : () => {}} />

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>写真を追加</ModalHeader>
          <ModalBody>
            {errorMessage && (
              <Message type="error" mb={2} h="auto" py={2} alignItems="start">
                <Text whiteSpace="pre-wrap">{errorMessage}</Text>
              </Message>
            )}
            {imageFile?.dataUrl ? (
              <Center h="240px" position="relative" mb={-2}>
                <Cropper
                  image={imageFile.dataUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 4}
                  onCropComplete={(_, area) => setAreaPixels(area)}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                />
              </Center>
            ) : (
              <VStack
                bg="blue.50"
                h="240px"
                p={8}
                alignItems="center"
                justifyContent="center"
                borderRadius={4}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  handleFiles(e.dataTransfer.files);
                }}
              >
                <Text textAlign="center">
                  ここに写真をドラッグ
                  <br />
                  または
                </Text>
                <Button my={2} onClick={() => inputRef.current?.click()}>
                  写真を追加
                </Button>
                <Input
                  type="file"
                  accept="image/jpeg,image/png"
                  display="none"
                  ref={inputRef}
                  onChange={(e) => handleFiles(e.target.files)}
                />
              </VStack>
            )}
          </ModalBody>
          <ModalFooter as={Flex} gap={3} justify="end">
            <Button variant="secondary" onClick={onClose}>
              キャンセル
            </Button>
            <Button
              isDisabled={!imageFile?.dataUrl || isUploading}
              onClick={handleOk}
            >
              決定
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

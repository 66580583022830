'use client';

import { Link } from '@chakra-ui/next-js';
import { Text } from '@chakra-ui/react';

type RefMessageProps = {
  text: string;
  link?: string;
};

export function RefMessage({ text, link = '' }: RefMessageProps) {
  return (
    <Text fontSize={12} mt={2}>
      店舗マスタの「
      <Link href={link} fontSize={12}>
        {text}
      </Link>
      」が反映されています。
    </Text>
  );
}

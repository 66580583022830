/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type ChartLegendKey = typeof ChartLegendKey[keyof typeof ChartLegendKey];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChartLegendKey = {
  keywordA: 'keywordA',
  keywordB: 'keywordB',
  keywordC: 'keywordC',
  keywordD: 'keywordD',
} as const;

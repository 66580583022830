import { MediaLabelData } from '@/components/File/AssetItemsPanel';
import { Icon } from '@/components/Icon';
import { GbpMediaCategory } from '@/lib/api/schema';
import { Box, Button, Center, Image, Text } from '@chakra-ui/react';
import { formatDate } from 'date-fns/format';
import { useState } from 'react';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

type AssetItemViewProps = {
  assetItem: MediaLabelData;
  imageWidth?: number;
  onClick?: () => void;
  onDeleteClick: (item: MediaLabelData) => void;
};

export function AssetItemView({
  assetItem,
  imageWidth,
  onClick,
  onDeleteClick,
}: AssetItemViewProps) {
  const [hover, setHover] = useState(false);

  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);
  return (
    <Box
      pos="relative"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Image
        src={assetItem.id ? assetItem.thumbnailUrl : assetItem.displayUrl}
        alt="img"
        w={imageWidth || 144}
        h={imageWidth || 144}
        borderRadius={4}
        objectFit="cover"
        cursor="pointer"
        transition={'filter 0.2s ease'}
        filter={hover ? 'brightness(70%)' : 'none'}
        onClick={onClick}
      />
      {!assetItem.id && assetItem.mediaFormat === 'VIDEO' && (
        <Center
          pos="absolute"
          top={`${imageWidth ? imageWidth / 2 - 24 : 48}px`}
          left={`${imageWidth ? imageWidth / 2 - 24 : 48}px`}
          borderRadius={4}
          zIndex={5}
        >
          <Icon name="video" size="xl" color="white" />
        </Center>
      )}
      {hover &&
        assetItem.category !== GbpMediaCategory.CUSTOMERS &&
        isStoreInfoEdit && (
          <Button
            variant="negative"
            pos="absolute"
            top={2}
            right={2}
            onClick={() => onDeleteClick(assetItem)}
          >
            削除
          </Button>
        )}
      <Text color="white" fontSize={12} pos="absolute" bottom={2} right={2}>
        {assetItem.createdAt && formatDate(assetItem.createdAt, 'yyyy.MM.dd')}
      </Text>
    </Box>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * メディア形式
 */
export type PostMediaMediaFormat = typeof PostMediaMediaFormat[keyof typeof PostMediaMediaFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostMediaMediaFormat = {
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 今回はGBPのみ
 */
export type ErrorStatusMedia = typeof ErrorStatusMedia[keyof typeof ErrorStatusMedia];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorStatusMedia = {
  GBP: 'GBP',
} as const;

import { getCompanyUploadS3Url } from '@/lib/api/client/company';
import { getStoreUploadS3Url } from '@/lib/api/client/store';
import { UploadPostMediaFunctionName } from '@/types/post';
import { useCallback, useState } from 'react';

type BulkUpdateFunctionName = 'bulkUpdateMultiPlatform' | 'bulkInsertStore';

export const useFileUpload = () => {
  const [isUploading, setIsUploading] = useState(false);

  const gbpFileUpload = useCallback(async (storeId: string, file: File) => {
    setIsUploading(true);
    try {
      const { name, type } = file;
      const response = await getStoreUploadS3Url(storeId, {
        functionName: 'gbp',
        fileName: name,
      });
      const res = await fetch(response.presignedUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': type },
        body: file,
      });
      if (res.ok) {
        return response.originalUrl;
      }
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setIsUploading(false);
    }
  }, []);

  const uploadBulkUpdateFile = useCallback(
    async (
      functionName: BulkUpdateFunctionName,
      companyId: string,
      file: File,
    ) => {
      setIsUploading(true);
      try {
        const { name, type } = file;
        const response = await getCompanyUploadS3Url(companyId, {
          functionName,
          fileName: name,
        });
        const res = await fetch(response.presignedUrl, {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': type },
          body: file,
        });
        if (res.ok) {
          return response.originalUrl;
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        setIsUploading(false);
      }
    },
    [],
  );

  const updatePostFile = useCallback(
    async (
      functionName: UploadPostMediaFunctionName,
      companyId: string,
      file: File,
    ) => {
      setIsUploading(true);
      try {
        const { name, type } = file;
        const response = await getCompanyUploadS3Url(companyId, {
          functionName,
          fileName: name,
        });
        const res = await fetch(response.presignedUrl, {
          method: 'PUT',
          mode: 'cors',
          headers: { 'Content-Type': type },
          body: file,
        });
        if (res.ok) {
          return response.originalUrl;
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        setIsUploading(false);
      }
    },
    [],
  );

  return {
    isUploading,
    gbpFileUpload,
    uploadBulkUpdateFile,
    updatePostFile,
  };
};

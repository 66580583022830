/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 投稿の承認ステータス
 */
export type PostApprovalStatus = typeof PostApprovalStatus[keyof typeof PostApprovalStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostApprovalStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;

import SelectStoresDialog, {
  SelectStoresDialogSubmitRef,
} from '@/components/Dialog/SelectStoresDialog';
import { Group } from '@/lib/api/schema';
import { Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';

type PostStoreGroupDialogProps = {
  companyId: string | null;
  storeIds: string[];
  isReadonly?: boolean;
  onSubmit: (storeIds: string[]) => void;
};

export function PostStoreGroupDialog({
  companyId,
  storeIds,
  isReadonly = false,
  onSubmit,
}: PostStoreGroupDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [storeName, setStoreName] = useState<string | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);

  const handleSubmit = (ref: SelectStoresDialogSubmitRef) => {
    setStoreName(ref.storeName);
    setGroups(ref.groups);
    onSubmit(ref.storeIds);
  };

  return (
    <>
      <Flex gap={4} alignItems="center">
        <Button
          variant="tertiary"
          fontWeight="normal"
          onClick={onOpen}
          isDisabled={isReadonly || !companyId}
        >
          店舗/グループ選択
        </Button>
        <Text>
          {companyId
            ? storeName && storeIds.length === 1
              ? `${storeName}を選択中`
              : groups.length > 0 && storeIds.length > 0
                ? `${groups.map((g) => g.name).join('、')}を選択中`
                : `${storeIds.length}店舗選択中`
            : '会社を選択してください'}
        </Text>
      </Flex>

      <SelectStoresDialog
        modalTitle="投稿する店舗"
        submitLabel="決定"
        companyId={companyId}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  );
}

import { Flex, Stack, Text, type StackProps } from '@chakra-ui/react';
import { Icon, IconName } from '@/components/Icon';

type MessageItem = {
  text: string;
  icon: {
    name: IconName;
    color?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  };
  fontSize?: string;
};

type MultiMessageProps = StackProps & {
  messages: MessageItem[];
};

export function MultiMessage({
  messages,
  children,
  ...rest
}: MultiMessageProps) {
  return (
    <Stack
      role="contentinfo"
      w="full"
      px={3}
      py={2.5}
      borderRadius="4px"
      fontSize={14}
      {...rest}
    >
      {messages.map((message) => (
        <Flex key={message.text} gap={2} alignItems="center">
          <Icon
            name={message.icon.name}
            size={message.icon.size}
            color={message.icon.color}
          />
          <Text fontSize={message.fontSize}>{message.text}</Text>
        </Flex>
      ))}
      {children}
    </Stack>
  );
}

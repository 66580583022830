'use client';

import {
  Box,
  Center,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FilterButton } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { Icon } from '@/components/Icon';
import { SearchBox } from '@/components/Input';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Brand, GeneralError } from '@/lib/api/schema';
import { getBrands } from '@/lib/api/client/brands';
import { DialogErrorMessage } from '@/components/Message';

type BrandDialogProps = {
  values: Brand[];
  onSubmit: (v: Brand[]) => void;
};

export function BrandDialog({ values, onSubmit }: BrandDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>(values || []);
  useEffect(() => {
    setSelectedBrands(values);
  }, [values]);

  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [word, setWord] = useState<string>('');
  const [canSearch, setCanSearch] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [responseStatus, setResponseStatus] = useState<number>(200);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setWord(value);
    if (value.length === 0) {
      handleSearch('');
    }
  }

  const handleSearch = useCallback(async (word: string) => {
    const params = word === '' ? undefined : { names: word.split(/[\s　]/) };
    try {
      const res = await getBrands(params);
      setFilteredBrands(res.items || []);
      setResponseStatus(200);
    } catch (error) {
      const e = error as GeneralError;
      setResponseStatus(e.status ?? -1);
    } finally {
      setCanSearch(false);
    }
  }, []);

  function handleRadioChange(value: string) {
    const newSelectedBrand = filteredBrands.find((b) => b.id === value);
    setSelectedBrands(newSelectedBrand ? [newSelectedBrand] : []);
  }

  function handleOpen() {
    onOpen();
    handleSearch('');
  }

  function handleSubmit() {
    onSubmit(selectedBrands);
    resetFilter();
  }

  function handleClose() {
    setSelectedBrands(values || []);
    resetFilter();
  }

  function resetFilter() {
    setWord('');
    onClose();
  }

  return (
    <>
      <FilterButton
        label="ブランド"
        isFilled={values.length > 0}
        width="auto"
        onClick={handleOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        title="ブランドで絞り込み"
        buttons={{
          primary: { onClick: handleSubmit },
          secondary: { onClick: handleClose },
        }}
        variant="fixedHeight"
      >
        <Box w="452px" pos="sticky" top="0" zIndex="1">
          <InputGroup size="md">
            <InputLeftElement>
              <Icon name="search" color="blue.500" />
            </InputLeftElement>
            <Input
              placeholder="ブランド名を検索"
              onInput={() => setCanSearch(true)}
              onChange={handleInputChange}
              onCompositionStart={() => setIsComposing(true)}
              onCompositionEnd={() => setIsComposing(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isComposing) handleSearch(word);
              }}
            />
          </InputGroup>
          {canSearch && word.length > 0 && (
            <SearchBox word={word} onClickSearchBox={handleSearch} />
          )}
        </Box>
        {responseStatus === 200 ? (
          <>
            {filteredBrands.length === 0 ? (
              <Center textAlign="center" h="300px">
                <Stack align="center" gap={6}>
                  <Icon name="noResults" color="gray.200" size="xl" />
                  <Stack gap={3}>
                    <Text size="md" color="gray.500">
                      該当するブランドが見つかりませんでした
                    </Text>
                    <Text size="sm" color="gray.400">
                      キーワードを変更してみたり、
                      <br />
                      入力ミスがないかをご確認ください。
                    </Text>
                  </Stack>
                </Stack>
              </Center>
            ) : (
              <Stack mt="16px">
                <RadioGroup
                  defaultValue={selectedBrands[0]?.id}
                  onChange={handleRadioChange}
                >
                  <Stack gap={2}>
                    {filteredBrands.map((brand) => (
                      <Radio key={brand.id} value={brand.id}>
                        {brand.name}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </Stack>
            )}
          </>
        ) : (
          <DialogErrorMessage responseStatus={responseStatus} h="300px" />
        )}
      </Modal>
    </>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 媒体で発生しうるステータスの全量
今回はGBPのみ
 */
export type ErrorStoreStatus = typeof ErrorStoreStatus[keyof typeof ErrorStoreStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorStoreStatus = {
  VERIFIED: 'VERIFIED',
  PENDING_VERIFIED: 'PENDING_VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  GOOGLE_UPDATED: 'GOOGLE_UPDATED',
  ERROR: 'ERROR',
  DUPLICATE: 'DUPLICATE',
  DISABLED: 'DISABLED',
  SUSPENDED: 'SUSPENDED',
  LINK_DISABLED: 'LINK_DISABLED',
  WAITING_FOR_VOICE_OF_MERCHANT: 'WAITING_FOR_VOICE_OF_MERCHANT',
} as const;

import { ja } from 'date-fns/locale';
import {
  default as ReactDatepicker,
  setDefaultLocale,
  registerLocale,
  type ReactDatePickerProps,
} from 'react-datepicker';
import '@/styles/DatePicker/datepicker.scss';
import { holidays } from '@/assets/i18n/ja';

import { Box } from '@chakra-ui/react';

registerLocale('ja', ja);
setDefaultLocale('ja');

// popper が input のDOMの傍にレンダーされるため Flex 等の gap がズレる。
// Box でラップすることで防止する。
export function DatePicker(props: ReactDatePickerProps) {
  return (
    <Box>
      <ReactDatepicker
        showPopperArrow={false}
        placeholderText="----.--.--"
        popperPlacement="bottom-start"
        dateFormat="yyyy.MM.dd"
        renderDayContents={(day) => <div>{day}</div>}
        holidays={holidays}
        {...props}
      />
    </Box>
  );
}

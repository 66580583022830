'use client';

import { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { RemoveButton, TertiaryButton } from '@/components/Button';
import { SelectTime } from '@/components/Select';
import { HourTypeLabels, Labels, StoreDayLabels } from '@/assets/i18n/ja';
import { BusinessHour, StoreBusinessDay } from '@/lib/api/schema';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { EditConfirmationDialog } from './EditConfirmationDialog';
import { validateHours } from '@/utils/validation';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

type BusinessDayForm = { days: StoreBusinessDay[] };

const initialData: StoreBusinessDay[] = [0, 1, 2, 3, 4, 5, 6].map((i) => {
  return {
    dayOfWeek: i,
    businessHourType: 'close',
    businessHours: null,
  } as StoreBusinessDay;
});

const storeInitialData: StoreBusinessDay[] = [0, 1, 2, 3, 4, 5, 6, 7, 8].map(
  (i) => {
    return {
      dayOfWeek: i,
      businessHourType: 'close',
      businessHours: null,
    } as StoreBusinessDay;
  },
);

export function BusinessDayDialog(props: {
  title: string;
  data: StoreBusinessDay[] | null;
  withHolidays?: boolean;
  removeResetButton?: boolean;
  onOk: (vals: StoreBusinessDay[] | null) => void;
}) {
  const { title, data, withHolidays = false, removeResetButton, onOk } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const form = useForm<BusinessDayForm>({
    defaultValues: { days: [] },
    shouldFocusError: false,
    criteriaMode: 'firstError',
    mode: 'onBlur',
  });
  const { control, formState, getValues, reset } = form;
  const { isValid } = formState;
  const { fields } = useFieldArray({ control, name: 'days' });
  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  useEffect(() => {
    if (isOpen)
      reset({
        days: data ? data : withHolidays ? storeInitialData : initialData,
      });
  }, [isOpen, reset, data, withHolidays]);

  function handleReset() {
    onOk(null);
    onClose();
  }

  function handleClose() {
    formState.isDirty ? setIsConfirmationOpen(true) : onClose();
  }

  function handleOk() {
    const retValue = getValues('days').map((el) => {
      const businessHours =
        el.businessHourType === 'openHours'
          ? el.businessHours!.sort((a, b) =>
              a.openTime! > b.openTime! ? 1 : -1,
            )
          : null;
      return { ...el, businessHours };
    });
    onOk(retValue);
    onClose();
  }

  return (
    <>
      {isConfirmationOpen && (
        <EditConfirmationDialog
          isValid={formState.isValid}
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onClickPrimary={handleOk}
          onClickSecondary={onClose}
        />
      )}

      <TertiaryButton
        label={data ? '編集' : `${title}を追加`}
        iconName={data ? 'editCircle' : 'addCircle'}
        onClick={onOpen}
        isDisabled={!isStoreInfoEdit}
      />

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          variant="fixedHeight"
          size="lg"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              <FormProvider {...form}>
                {fields?.map((field, index) => (
                  <DayHourView key={field.id} index={index} />
                ))}
              </FormProvider>
            </ModalBody>
            <ModalFooter>
              {!removeResetButton && (
                <Button variant="text" onClick={handleReset}>
                  {Labels.button.reset}
                </Button>
              )}
              <Button variant="secondary" onClick={handleClose}>
                {Labels.button.cancel}
              </Button>
              <Button isDisabled={!isValid} onClick={handleOk}>
                {Labels.button.done}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

function DayHourView({ index }: { index: number }) {
  const { control, formState, getValues } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormContext<BusinessDayForm, any, BusinessDayForm>();
  const { errors, isValid } = formState;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `days.${index}.businessHours` as const,
    rules: {
      validate: (hours: BusinessHour[], { days }: BusinessDayForm) => {
        if (days[index].businessHourType !== 'openHours') return true;
        return validateHours(hours);
      },
    },
  });

  const dayOfWeek = getValues(`days.${index}.dayOfWeek`);

  return (
    <Controller
      control={control}
      name={`days.${index}.businessHourType`}
      render={({ field: { value, onChange } }) => (
        <Flex align="start" py={2}>
          <Text w={16}>{StoreDayLabels[dayOfWeek]}</Text>
          <Switch
            w={32}
            my={1}
            isChecked={value !== 'close'}
            onChange={(e) => {
              const v = e.target.checked ? 'openHours' : 'close';
              if (v === 'openHours' && fields.length === 0) {
                append({ openTime: null, closeTime: null });
              }
              onChange(v);
            }}
          >
            {value === 'close' ? '定休日' : '営業日'}
          </Switch>
          <Flex direction="column" gap={2} align="start">
            {value !== 'close' && (
              <RadioGroup
                value={value}
                onChange={(v) => {
                  if (v === 'openHours' && fields.length === 0) {
                    append({ openTime: null, closeTime: null });
                  }
                  onChange(v);
                }}
              >
                <Flex align="center" gap={4}>
                  <Radio value={'openHours'}>{HourTypeLabels.openHours}</Radio>
                  <Radio value={'open24h'}>{HourTypeLabels.open24h}</Radio>
                </Flex>
              </RadioGroup>
            )}
            {value === 'openHours' &&
              fields?.map(({ id, openTime, closeTime }, i) => {
                let errorMessage: string | null = null;
                if (!openTime || !closeTime)
                  errorMessage = '時間を入力して下さい。';
                return (
                  <Fragment key={id}>
                    <Flex gap={2} align="center">
                      <SelectTime
                        placeholder="開始"
                        value={openTime}
                        onChange={(v) => update(i, { closeTime, openTime: v })}
                      />
                      {' - '}
                      <SelectTime
                        placeholder="終了"
                        value={closeTime}
                        onChange={(v) => update(i, { openTime, closeTime: v })}
                      />
                      <RemoveButton
                        onClick={() => {
                          remove(i);
                          if (fields.length < 2) onChange('close');
                        }}
                      />
                    </Flex>
                    {errorMessage && (
                      <Text variant="invalid">{errorMessage}</Text>
                    )}
                  </Fragment>
                );
              })}
            {value === 'openHours' && !isValid && (
              <ErrorMessage
                errors={errors}
                name={`days.${index}.businessHours.root`}
                render={({ message }) => (
                  <Text variant="invalid">{message}</Text>
                )}
              />
            )}
            {value === 'openHours' && fields.length < 3 && (
              <TertiaryButton
                label="営業時間を追加"
                iconName="addCircle"
                onClick={() => append({ openTime: null, closeTime: null })}
              />
            )}
          </Flex>
        </Flex>
      )}
    />
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type GetReviewsRatingItem = typeof GetReviewsRatingItem[keyof typeof GetReviewsRatingItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReviewsRatingItem = {
  NUMBER_1: '1',
  NUMBER_2: '2',
  NUMBER_3: '3',
  NUMBER_4: '4',
  NUMBER_5: '5',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 処理結果
- SUCCESS: 成功
- FAILURE: 失敗

 */
export type InvitationResultItemResult = typeof InvitationResultItemResult[keyof typeof InvitationResultItemResult];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvitationResultItemResult = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;

import { Box, Flex, type FlexProps } from '@chakra-ui/react';
import { Icon, IconName } from '@/components/Icon';

type MessageType =
  | 'normal'
  | 'schedule'
  | 'error'
  | 'scheduleBlue'
  | 'errorOrange'
  | 'endSchedule'
  | 'noEdit'
  | 'progress';

type MessageTheme = { bg: string; icon: { name: IconName; color: string } };

type MessageProps = FlexProps & {
  type?: MessageType;
};

const theme: Record<MessageType, MessageTheme> = {
  normal: {
    bg: 'orange.100',
    icon: { name: 'attention', color: 'orange.500' },
  },
  error: { bg: 'red.100', icon: { name: 'attention', color: 'red.500' } },
  errorOrange: {
    bg: 'orange.100',
    icon: { name: 'attention', color: 'red.500' },
  },
  schedule: { bg: 'white', icon: { name: 'calendar', color: 'blue.500' } },
  scheduleBlue: {
    bg: 'blue.100',
    icon: { name: 'calendar', color: 'blue.500' },
  },
  endSchedule: {
    bg: 'gray.100',
    icon: { name: 'calendar', color: 'gray.500' },
  },
  noEdit: {
    bg: 'gray.100',
    icon: { name: 'editCircle', color: 'gray.500' },
  },
  progress: {
    bg: 'gray.100',
    icon: { name: 'sync', color: 'gray.500' },
  },
} as const;

export function Message({ type = 'normal', children, ...rest }: MessageProps) {
  const { bg, icon } = theme[type];
  return (
    <Flex
      role="contentinfo"
      w="full"
      h={9}
      p={3}
      gap={2}
      alignItems="center"
      bg={bg}
      borderRadius="4px"
      fontSize={14}
      {...rest}
    >
      <Box my={1}>
        <Icon name={icon.name} size="sm" color={icon.color} />
      </Box>
      {children}
    </Flex>
  );
}

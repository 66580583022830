/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 媒体種別
 */
export type PostGroupPlatformType = typeof PostGroupPlatformType[keyof typeof PostGroupPlatformType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostGroupPlatformType = {
  GBP: 'GBP',
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  YAHOO: 'YAHOO',
} as const;

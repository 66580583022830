/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 投稿の検索条件タイプ
 */
export type PostSearchType = typeof PostSearchType[keyof typeof PostSearchType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostSearchType = {
  GBP_EVENT: 'GBP_EVENT',
  GBP_STANDARD: 'GBP_STANDARD',
  GBP_OFFER: 'GBP_OFFER',
  GBP_FROM_INSTAGRAM: 'GBP_FROM_INSTAGRAM',
  YAHOO_NOTICE: 'YAHOO_NOTICE',
  YAHOO_DAYOFF: 'YAHOO_DAYOFF',
  YAHOO_CAMPAIGN: 'YAHOO_CAMPAIGN',
  YAHOO_ETC: 'YAHOO_ETC',
  YAHOO_FROM_INSTAGRAM: 'YAHOO_FROM_INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
} as const;

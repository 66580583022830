/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 媒体種別
 */
export type ReviewMediaType = typeof ReviewMediaType[keyof typeof ReviewMediaType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewMediaType = {
  GOOGLE_REVIEW: 'GOOGLE_REVIEW',
  GOOGLE_QUESTION: 'GOOGLE_QUESTION',
  YAHOO_REVIEW: 'YAHOO_REVIEW',
  FACEBOOK_COMMENT: 'FACEBOOK_COMMENT',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 曜日
* 0:Sunday,
* 1:Monday,
* 2:Tuesday,
* 3:Wednesday,
* 4:Thursday,
* 5:Friday,
* 6:Saturday
* 7:祝日
* 8:祝前日
 */
export type StoreBusinessDayDayOfWeek = typeof StoreBusinessDayDayOfWeek[keyof typeof StoreBusinessDayDayOfWeek];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreBusinessDayDayOfWeek = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_5: 5,
  NUMBER_6: 6,
  NUMBER_7: 7,
  NUMBER_8: 8,
} as const;

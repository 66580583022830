import { UserRole } from '@/types/user';

export const roles: UserRole[] = [
  { name: 'administrator', label: 'アドミン' },
  { name: 'manager', label: '管理者' },
  { name: 'editor', label: '編集者' },
  { name: 'worker_store_edit', label: '作業者（店舗情報編集可）' },
  { name: 'worker', label: '作業者' },
  { name: 'viewer', label: '閲覧者' },
];

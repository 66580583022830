/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 投稿タイプ。最新情報（STANDARD）/イベント（EVENT）/特典（OFFER）
 */
export type PostGbpTopicType = typeof PostGbpTopicType[keyof typeof PostGbpTopicType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostGbpTopicType = {
  STANDARD: 'STANDARD',
  EVENT: 'EVENT',
  OFFER: 'OFFER',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 価格帯タイプ
* 0:未定：価格帯を指定しない場合に選択してください
* 1:￥：安い
* 2:￥￥：中程度
* 3:￥￥￥：高い
* 4:￥￥￥￥：かなり高い
* 99:該当なし：価格帯は利用できません
 */
export type FacebookPageDetailMutablePriceRange = typeof FacebookPageDetailMutablePriceRange[keyof typeof FacebookPageDetailMutablePriceRange];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FacebookPageDetailMutablePriceRange = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
  NUMBER_99: 99,
} as const;

import { Icon } from '@/components/Icon';
import { Tooltip, type IconButtonProps, IconButton } from '@chakra-ui/react';

type RemoveButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  label?: string;
  iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export function RemoveButton({
  label = '削除',
  iconSize,
  onClick,
  ...rest
}: RemoveButtonProps) {
  return (
    <Tooltip placement="top" hasArrow label={label}>
      <IconButton variant="text" onClick={onClick} {...rest} aria-label={label}>
        <Icon name="closeCircle" size={iconSize} />
      </IconButton>
    </Tooltip>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type SortByParameter = typeof SortByParameter[keyof typeof SortByParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortByParameter = {
  STORE_CODE: 'STORE_CODE',
  NAME: 'NAME',
  UPDATED_AT: 'UPDATED_AT',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 属性タイプ
* BOOL
* TEXT: テキストのみの項目 (e.g. 「衛生対策実施」のその他対策の説明文)
 */
export type YahooAttributeItemValueType = typeof YahooAttributeItemValueType[keyof typeof YahooAttributeItemValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YahooAttributeItemValueType = {
  BOOL: 'BOOL',
  TEXT: 'TEXT',
} as const;

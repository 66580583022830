'use client';

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Center,
  CheckboxGroup,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
  Box,
  Link,
} from '@chakra-ui/react';
import { FilterButton } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { Icon } from '@/components/Icon';
import { SearchBox } from '@/components/Input';
import { GeneralError, Group } from '@/lib/api/schema';
import { getGroups } from '@/lib/api/client/group';
import { DialogErrorMessage } from '@/components/Message';

type GroupDialogProps = {
  values: Group[];
  onSubmit: (v: Group[]) => void;
};

export function GroupDialog({ values, onSubmit }: GroupDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(values || []);
  useEffect(() => {
    setSelectedGroups(values);
  }, [values]);

  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [word, setWord] = useState<string>('');
  const [canSearch, setCanSearch] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [groupExists, setGroupExists] = useState(true);
  const [responseStatus, setResponseStatus] = useState<number>(200);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setWord(value);
    if (value.length === 0) {
      handleSearch('');
    }
  }

  const handleSearch = useCallback(async (word: string) => {
    const params = word === '' ? undefined : { names: word.split(/[\s　]/) };
    try {
      const res = await getGroups(params);
      setFilteredGroups(res.items || []);
      if (!params && !res.items) {
        setGroupExists(false);
      }
      setResponseStatus(200);
    } catch (error) {
      const e = error as GeneralError;
      setResponseStatus(e.status ?? -1);
    } finally {
      setCanSearch(false);
    }
  }, []);

  function handleCheckboxChange(values: (string | number)[]) {
    // 検索結果外で選択されているグループはそのまま残す
    const retainedGroups = selectedGroups.filter(
      (g) => !filteredGroups.some((fg) => fg.id === g.id),
    );
    const newSelectedGroups = filteredGroups.filter((g) =>
      values.includes(g.id),
    );
    setSelectedGroups([...retainedGroups, ...newSelectedGroups]);
  }

  function handleOpen() {
    onOpen();
    handleSearch('');
  }

  function handleSubmit() {
    onSubmit(selectedGroups);
    resetFilter();
  }

  function handleClose() {
    setSelectedGroups(values || []);
    resetFilter();
  }

  function resetFilter() {
    setWord('');
    setGroupExists(true);
    onClose();
  }

  return (
    <>
      <FilterButton
        label="グループ"
        isFilled={values.length > 0}
        width="auto"
        onClick={handleOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        title="グループで絞り込み"
        buttons={{
          primary: { onClick: handleSubmit },
          secondary: { onClick: handleClose },
        }}
        variant="fixedHeight"
      >
        <Box w="452px" pos="sticky" top="0" zIndex="1">
          <InputGroup size="md">
            <InputLeftElement>
              <Icon name="search" color="blue.500" />
            </InputLeftElement>
            <Input
              placeholder="グループ名を検索"
              onInput={() => setCanSearch(true)}
              onChange={handleInputChange}
              onCompositionStart={() => setIsComposing(true)}
              onCompositionEnd={() => setIsComposing(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !isComposing) handleSearch(word);
              }}
            />
          </InputGroup>
          {canSearch && word.length > 0 && (
            <SearchBox word={word} onClickSearchBox={handleSearch} />
          )}
        </Box>
        {responseStatus === 200 ? (
          <>
            {groupExists ? (
              <>
                {filteredGroups.length === 0 ? (
                  <Center textAlign="center" h="300px">
                    <Stack align="center" gap={6}>
                      <Icon name="noResults" color="gray.200" size="xl" />
                      <Stack gap={3}>
                        <Text size="md" color="gray.500">
                          該当するグループが見つかりませんでした
                        </Text>
                        <Text size="sm" color="gray.400">
                          キーワードを変更してみたり、
                          <br />
                          入力ミスがないかをご確認ください。
                        </Text>
                      </Stack>
                    </Stack>
                  </Center>
                ) : (
                  <Stack mt="16px">
                    <CheckboxGroup
                      defaultValue={selectedGroups.map((b) => b.id)}
                      onChange={handleCheckboxChange}
                    >
                      {filteredGroups.map((group) => (
                        <Checkbox key={group.id} value={group.id}>
                          {group.name}
                        </Checkbox>
                      ))}
                    </CheckboxGroup>
                  </Stack>
                )}
              </>
            ) : (
              <Center flexDirection="column" h={300}>
                <Text>まだグループがありません</Text>
                <Link href={``}>グループを作成</Link>
              </Center>
            )}
          </>
        ) : (
          <DialogErrorMessage responseStatus={responseStatus} h="300px" />
        )}
      </Modal>
    </>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 属性タイプ
* BOOL
* ENUM
* REPEATED_ENUM
 */
export type GbpAttributeValueType = typeof GbpAttributeValueType[keyof typeof GbpAttributeValueType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GbpAttributeValueType = {
  BOOL: 'BOOL',
  ENUM: 'ENUM',
  REPEATED_ENUM: 'REPEATED_ENUM',
} as const;

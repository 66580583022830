'use client';

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

type DeleteStoreDialogProps = {
  storeName: string;
  isDisable?: boolean;
  remove: () => void;
};

export function DeleteStoreDialog({
  storeName,
  isDisable,
  remove,
}: DeleteStoreDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button variant="negative" onClick={onOpen} isDisabled={isDisable}>
        削除
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>店舗の削除</ModalHeader>
          <ModalBody>
            <Text>「{storeName}」を削除しますか？</Text>
            <Text>この操作は元に戻せません。</Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={onClose}>
              閉じる
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                remove();
                onClose();
              }}
            >
              削除
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

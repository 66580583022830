import { OptionsResponse } from '@/lib/api/schema';
import { atom } from 'jotai';

export const optionAtom = atom<OptionsResponse | null>(null);

export const hasOptionsAtom = atom((get) => {
  const option = get(optionAtom);

  return {
    hasGbpOption:
      option?.isAdministrator || option?.options?.includes('gbp') || false,
    hasFacebookOption:
      option?.isAdministrator || option?.options?.includes('facebook') || false,
    hasInstagramOption:
      option?.isAdministrator ||
      option?.options?.includes('instagram') ||
      false,
    hasAppleOption:
      option?.isAdministrator || option?.options?.includes('apple') || false,
    hasYahooOption:
      option?.isAdministrator || option?.options?.includes('yahoo') || false,
    hasMeoRankingOption:
      option?.isAdministrator ||
      option?.options?.includes('meo_ranking') ||
      false,
    hasInstagramPostOption:
      option?.isAdministrator ||
      option?.options?.includes('instagram_posts_multi_platform_posting') ||
      false,
    hasProtectionOption:
      option?.isAdministrator ||
      option?.options?.includes('tamper_protection') ||
      false,
  };
});

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  ErrorForbiddenResponse,
  ErrorInternalServerResponse,
  ErrorUnauthenticatedResponse,
  GetGroupsParams,
  Groups
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * グローバルフィルターに表示するグループ一覧を取得するAPI。
ログインユーザーが参照できるグループのみ取得。

グループ名、会社IDでの検索が可能。未指定の場合は全件取得。

 * @summary グループ一覧を取得する
 */
export const getGroups = (
    params?: GetGroupsParams,
 ) => {
      return customInstance<Groups>(
      {url: `/groups`, method: 'GET',
        params
    },
      );
    }
  


export const getGetGroupsKey = (params?: GetGroupsParams,) => [`/groups`, ...(params ? [params]: [])] as const;


export type GetGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getGroups>>>
export type GetGroupsQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary グループ一覧を取得する
 */
export const useGetGroups = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  params?: GetGroupsParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getGroups>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetGroupsKey(params) : null);
  const swrFn = () => getGroups(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

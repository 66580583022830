/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type PostDetailPlatform = typeof PostDetailPlatform[keyof typeof PostDetailPlatform];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostDetailPlatform = {
  GBP: 'GBP',
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  YAHOO: 'YAHOO',
} as const;

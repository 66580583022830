import { Icon } from '../Icon/Icon';
import { Button, ButtonProps } from '@chakra-ui/react';

export function AddItemButton({
  text,
  ...rest
}: { text: string } & ButtonProps) {
  return (
    <Button
      variant="tertiary"
      size="sm"
      leftIcon={<Icon name="addCircle" color="blue.500" size="sm" />}
      {...rest}
    >
      {text}
    </Button>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 返信テンプレート種別
 */
export type ReplyTemplateType = typeof ReplyTemplateType[keyof typeof ReplyTemplateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReplyTemplateType = {
  COMMON: 'COMMON',
  BRAND: 'BRAND',
} as const;

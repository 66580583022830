import { Labels } from '@/assets/i18n/ja';
import { Modal } from '@/components/Modal';

export function EditConfirmationDialog(props: {
  isOpen: boolean;
  isValid?: boolean;
  setIsOpen: (b: boolean) => void;
  onClickPrimary: () => void;
  onClickSecondary: () => void;
}) {
  const { isOpen, isValid, setIsOpen, onClickPrimary, onClickSecondary } =
    props;

  return (
    <Modal
      title="変更内容を保存していません"
      message="モーダルを閉じる前に、変更内容を保存しますか？"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      buttons={{
        primary: {
          label: Labels.button.save,
          isDisabled: !isValid,
          onClick: () => {
            setIsOpen(false);
            onClickPrimary();
          },
        },
        secondary: {
          label: Labels.button.noSave,
          onClick: () => {
            setIsOpen(false);
            onClickSecondary();
          },
        },
        text: {
          label: Labels.button.backToEdit,
          onClick: () => setIsOpen(false),
        },
      }}
    />
  );
}

import { AssetItemView } from './AssetItemView';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import { MediaLabelData } from '@/components/File/AssetItemsPanel';
import { useAtomValue } from 'jotai';
import { storeAbilitiesAtom } from '@/lib/atoms/abilities';

type AssetItemsDialogProps = {
  label: string;
  items: MediaLabelData[];
  onClick: (item: MediaLabelData) => void;
  onDeleteClick: (item: MediaLabelData) => void;
};

export function AssetItemsDialog({
  label,
  items,
  onClick,
  onDeleteClick,
}: AssetItemsDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isStoreInfoEdit } = useAtomValue(storeAbilitiesAtom);

  return (
    <>
      <Button
        variant="text"
        fontWeight={400}
        onClick={onOpen}
        isDisabled={!isStoreInfoEdit}
      >
        もっと見る
      </Button>

      {isOpen && (
        <Modal
          variant="fixedHeight"
          isOpen={isOpen}
          onClose={onClose}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{label}</ModalHeader>
            <ModalCloseButton onClick={onClose} />
            <ModalBody>
              <SimpleGrid columns={6} gap={2}>
                {items.map((item, i) => (
                  <AssetItemView
                    key={i}
                    imageWidth={132}
                    assetItem={{ ...item }}
                    onClick={() => onClick({ ...item })}
                    onDeleteClick={() => onDeleteClick({ ...item })}
                  />
                ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button variant="secondary" onClick={onClose}>
                閉じる
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

'use client';

import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { MultiMessage } from '@/components/Message';
import { useState } from 'react';

type RejectReason = {
  name: string;
  date: string;
  detail: string;
};

type ReviewRejectedMessageProps = {
  name: string;
  reasons: RejectReason[];
};

export function ReviewRejectedMessage({
  name,
  reasons,
}: ReviewRejectedMessageProps) {
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);

  return (
    <MultiMessage
      messages={[
        {
          text: `${name}が承認申請を却下しました。申請内容を編集して、再申請してください。`,
          fontSize: '12px',
          icon: {
            name: 'attention',
            color: 'red.500',
            size: 'sm',
          },
        },
      ]}
      bgColor="red.100"
    >
      {reasons.map(
        (reason, i) =>
          (isDetailOpen || i === 0) && (
            <Stack key={`${reason.name}_${i}`}>
              <Flex alignItems="center">
                <Text color="red.500" fontWeight="bold" fontSize="sm">
                  却下理由
                </Text>
                <Text color="gray.400" fontSize="xs">
                  （{reason.name}, {reason.date}）
                </Text>
              </Flex>
              <Text fontSize="sm" whiteSpace="pre-wrap">
                {reason.detail}
              </Text>
            </Stack>
          ),
      )}
      {reasons.length > 1 && (
        <Box
          color="blue.500"
          fontSize="sm"
          cursor="pointer"
          mr="auto"
          onClick={() => setIsDetailOpen(!isDetailOpen)}
        >
          {isDetailOpen ? '履歴をたたむ' : '履歴を見る'}
        </Box>
      )}
    </MultiMessage>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import {
  z as zod
} from '@/assets/i18n'


/**
 * 順位取得に使用するキーワードの一覧を取得するAPI
 * @summary 順位取得に使用するキーワードの一覧を取得する
 */
export const getRankingKeywordsParams = zod.object({
  "storeId": zod.string().min(1)
})

export const getRankingKeywordsResponseKeywordsItemMax = 255;
export const getRankingKeywordsResponseKeywordsMax = 4;


export const getRankingKeywordsResponse = zod.object({
  "keywords": zod.array(zod.string().max(getRankingKeywordsResponseKeywordsItemMax)).max(getRankingKeywordsResponseKeywordsMax).nullable()
})

/**
 * 順位取得に使用するキーワードを更新する
 * @summary 順位取得に使用するキーワードを更新する
 */
export const upsertRankingKeywordsParams = zod.object({
  "storeId": zod.string().min(1)
})

export const upsertRankingKeywordsBodyKeywordsItemMax = 255;
export const upsertRankingKeywordsBodyKeywordsMax = 4;


export const upsertRankingKeywordsBody = zod.object({
  "keywords": zod.array(zod.string().max(upsertRankingKeywordsBodyKeywordsItemMax)).max(upsertRankingKeywordsBodyKeywordsMax).nullable()
})

export const upsertRankingKeywordsResponse = zod.object({
  "success": zod.boolean()
})

/**
 * API実行時点から31日分の検索順位を取得する
 * @summary API実行時点から31日分の検索順位を取得する
 */
export const getRankingResultsParams = zod.object({
  "storeId": zod.string().min(1)
})

export const getRankingResultsResponseRankingResultsMax = 31;
export const getRankingResultsResponseChartLegendsMax = 4;


export const getRankingResultsResponse = zod.object({
  "rankingResults": zod.array(zod.object({
  "date": zod.string().date(),
  "keywordA": zod.number().optional(),
  "keywordB": zod.number().optional(),
  "keywordC": zod.number().optional(),
  "keywordD": zod.number().optional()
})).max(getRankingResultsResponseRankingResultsMax).nullable(),
  "chartLegends": zod.array(zod.object({
  "key": zod.enum(['keywordA', 'keywordB', 'keywordC', 'keywordD']).optional(),
  "word": zod.string().optional()
})).max(getRankingResultsResponseChartLegendsMax).nullable(),
  "yticks": zod.array(zod.number())
})

/**
 * 順位取得結果のPDFファイル作成をworkerへ依頼する
 * @summary 順位取得結果のPDFファイル作成をworkerへ依頼する
 */
export const requestCreateRankingPdfBody = zod.object({
  "storeIds": zod.array(zod.string()).nullable()
})

export const requestCreateRankingPdfResponse = zod.object({
  "success": zod.boolean()
})

/**
 * 順位取得結果のCSVファイルを作成する
 * @summary 順位取得結果のCSVファイルを作成する
 */
export const createRankingCsvBody = zod.object({
  "storeIds": zod.array(zod.string()).nullable()
})

export const createRankingCsvResponse = zod.object({
  "presignedUrl": zod.string().url(),
  "originalUrl": zod.string()
})


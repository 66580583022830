import { PageTransitionDialog } from '@/components/Dialog';
import { Link, LinkProps } from '@chakra-ui/next-js';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';

type ConfirmLinkProps = Omit<LinkProps, 'children'> & {
  href: string;
  isDirty?: boolean;
  isValid?: boolean;
  onSave?: () => void;
  children: React.ReactNode;
};

export function ConfirmLink({
  href,
  isDirty,
  isValid = true,
  onSave,
  children,
  ...rest
}: ConfirmLinkProps) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const handleSave = useCallback(() => {
    if (onSave) onSave();
    router.push(href);
  }, [href, onSave, router]);

  return (
    <>
      <Link
        href={href}
        onClick={(e) => {
          if (!isDirty) return;
          e.preventDefault();
          setIsOpen(true);
        }}
        {...rest}
      >
        {children}
      </Link>
      {isDirty && (
        <PageTransitionDialog
          isOpen={isOpen}
          isValid={isValid}
          onClose={() => setIsOpen(false)}
          onSave={handleSave}
          onNoSave={() => router.push(href)}
        />
      )}
    </>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * - all: 全店舗を管理
- partial: 一部店舗を管理
- none: 管理店舗なし

 */
export type StoreManagementSettingItemManagementType = typeof StoreManagementSettingItemManagementType[keyof typeof StoreManagementSettingItemManagementType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreManagementSettingItemManagementType = {
  all: 'all',
  partial: 'partial',
  none: 'none',
} as const;

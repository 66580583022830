'use client';

import { Icon } from '@/components/Icon';
import { Center, CenterProps, Stack, Text } from '@chakra-ui/react';

type DialogErrorMessageProps = {
  responseStatus: number;
} & CenterProps;

export function DialogErrorMessage({
  responseStatus,
  ...rest
}: DialogErrorMessageProps) {
  return (
    <Center textAlign="center" {...rest}>
      <Stack align="center" gap={6}>
        <Icon name="noResults" color="gray.200" size="xl" />
        <Stack gap={3}>
          <Text size="md" color="gray.500">
            {responseStatus >= 500
              ? '一時的なエラーが発生しました。'
              : '検索中にエラーが発生しました。'}
          </Text>
          <Text size="sm" color="gray.400">
            {responseStatus >= 500
              ? '数分ほど時間をおいてもう一度お試しください。'
              : '担当者にお問い合わせください。'}
          </Text>
        </Stack>
      </Stack>
    </Center>
  );
}

import {
  AbilityBuilder,
  AbilityTuple,
  MongoAbility,
  MongoQuery,
  createMongoAbility,
} from '@casl/ability';
import { atom } from 'jotai';

type PermissionType = {
  subject: string;
  action: string;
};

// Abilityの定義
export const defineAbilityFor = (
  permissions: PermissionType[],
  isAdmin: boolean,
) => {
  const { can, build } = new AbilityBuilder(createMongoAbility);

  if (isAdmin) {
    can('manage', 'all'); // 管理者はすべての操作が可能
  } else {
    permissions.map((item) => can(item.subject, item.action));
  }

  return build();
};

export const abilityAtom = atom<MongoAbility<AbilityTuple, MongoQuery> | null>(
  null,
);

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr'
import type {
  Arguments,
  Key,
  SWRConfiguration
} from 'swr'
import useSWRMutation from 'swr/mutation'
import type {
  SWRMutationConfiguration
} from 'swr/mutation'
import type {
  ErrorForbiddenResponse,
  ErrorInternalServerResponse,
  ErrorMultiStatusResponse,
  ErrorNotFoundResponse,
  ErrorUnauthenticatedResponse,
  ErrorUnprocessableResponse,
  ErrorValidationResponse,
  FileDownloadLink,
  GetStoreErrorStatuses200,
  GetStoreErrorStatusesParams,
  GetStoreListCsvDownloadUrlParams,
  GetStoreUploadS3UrlParams,
  GetStoresParams,
  Store,
  StoreLinkedInformation,
  StoreList,
  StoreName,
  Success,
  UpdateMultiResourcesSuccess,
  UpdateStoreBody
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * 店舗名称が取得できること
 * @summary 店舗名称を取得する
 */
export const getStoreName = (
    storeId: string,
 ) => {
      return customInstance<StoreName>(
      {url: `/stores/${storeId}/name`, method: 'GET'
    },
      );
    }
  


export const getGetStoreNameKey = (storeId: string,) => [`/stores/${storeId}/name`] as const;


export type GetStoreNameQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreName>>>
export type GetStoreNameQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗名称を取得する
 */
export const useGetStoreName = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  storeId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreName>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(storeId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreNameKey(storeId) : null);
  const swrFn = () => getStoreName(storeId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗マスタが取得できること
 * @summary 店舗マスタを取得する
 */
export const getStore = (
    storeId: string,
 ) => {
      return customInstance<Store>(
      {url: `/stores/${storeId}`, method: 'GET'
    },
      );
    }
  


export const getGetStoreKey = (storeId: string,) => [`/stores/${storeId}`] as const;


export type GetStoreQueryResult = NonNullable<Awaited<ReturnType<typeof getStore>>>
export type GetStoreQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗マスタを取得する
 */
export const useGetStore = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  storeId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStore>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(storeId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreKey(storeId) : null);
  const swrFn = () => getStore(storeId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗マスタを更新する
 * @summary 店舗マスタを更新する
 */
export const updateStore = (
    storeId: string,
    updateStoreBody: UpdateStoreBody,
 ) => {
      return customInstance<UpdateMultiResourcesSuccess>(
      {url: `/stores/${storeId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateStoreBody
    },
      );
    }
  


export const getUpdateStoreMutationFetcher = (storeId: string, ) => {
  return (_: string, { arg }: { arg: UpdateStoreBody }): Promise<UpdateMultiResourcesSuccess> => {
    return updateStore(storeId, arg);
  }
}
export const getUpdateStoreMutationKey = (storeId: string,) => `/stores/${storeId}` as const;

export type UpdateStoreMutationResult = NonNullable<Awaited<ReturnType<typeof updateStore>>>
export type UpdateStoreMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorMultiStatusResponse | ErrorUnprocessableResponse | ErrorInternalServerResponse

/**
 * @summary 店舗マスタを更新する
 */
export const useUpdateStore = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorMultiStatusResponse | ErrorUnprocessableResponse | ErrorInternalServerResponse>(
  storeId: string, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof updateStore>>, TError, string, UpdateStoreBody, Awaited<ReturnType<typeof updateStore>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateStoreMutationKey(storeId);
  const swrFn = getUpdateStoreMutationFetcher(storeId,);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗マスタとそれに関連するデータを論理削除する
 * @summary 店舗マスタの削除
 */
export const deleteStore = (
    storeId: string,
 ) => {
      return customInstance<Success>(
      {url: `/stores/${storeId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteStoreMutationFetcher = (storeId: string, ) => {
  return (_: string, __: { arg: Arguments }): Promise<Success> => {
    return deleteStore(storeId);
  }
}
export const getDeleteStoreMutationKey = (storeId: string,) => `/stores/${storeId}` as const;

export type DeleteStoreMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStore>>>
export type DeleteStoreMutationError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗マスタの削除
 */
export const useDeleteStore = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  storeId: string, options?: { swr?:SWRMutationConfiguration<Awaited<ReturnType<typeof deleteStore>>, TError, string, Arguments, Awaited<ReturnType<typeof deleteStore>>> & { swrKey?: string },  }
) => {

  const {swr: swrOptions} = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteStoreMutationKey(storeId);
  const swrFn = getDeleteStoreMutationFetcher(storeId,);

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * S3オブジェクトの PresignedURL が取得できる
 * @summary 店舗に関連するS3 PresignedURL を取得する
 */
export const getStoreUploadS3Url = (
    storeId: string,
    params: GetStoreUploadS3UrlParams,
 ) => {
      return customInstance<FileDownloadLink>(
      {url: `/stores/${storeId}/upload-s3-url`, method: 'GET',
        params
    },
      );
    }
  


export const getGetStoreUploadS3UrlKey = (storeId: string,
    params: GetStoreUploadS3UrlParams,) => [`/stores/${storeId}/upload-s3-url`, ...(params ? [params]: [])] as const;


export type GetStoreUploadS3UrlQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreUploadS3Url>>>
export type GetStoreUploadS3UrlQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗に関連するS3 PresignedURL を取得する
 */
export const useGetStoreUploadS3Url = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  storeId: string,
    params: GetStoreUploadS3UrlParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreUploadS3Url>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(storeId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreUploadS3UrlKey(storeId,params) : null);
  const swrFn = () => getStoreUploadS3Url(storeId,params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗情報CSVが置かれたS3のPresignedURLが取得できる。
 * @summary 店舗情報CSVをダウンロードするPresignedURLを取得する
 */
export const getStoreListCsvDownloadUrl = (
    params?: GetStoreListCsvDownloadUrlParams,
 ) => {
      return customInstance<FileDownloadLink>(
      {url: `/stores/list/download-csv-url`, method: 'GET',
        params
    },
      );
    }
  


export const getGetStoreListCsvDownloadUrlKey = (params?: GetStoreListCsvDownloadUrlParams,) => [`/stores/list/download-csv-url`, ...(params ? [params]: [])] as const;


export type GetStoreListCsvDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreListCsvDownloadUrl>>>
export type GetStoreListCsvDownloadUrlQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗情報CSVをダウンロードするPresignedURLを取得する
 */
export const useGetStoreListCsvDownloadUrl = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  params?: GetStoreListCsvDownloadUrlParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreListCsvDownloadUrl>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreListCsvDownloadUrlKey(params) : null);
  const swrFn = () => getStoreListCsvDownloadUrl(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * GBP, Instagram など各種媒体と店舗の連携ステータスを取得します
 * @summary 媒体連携ステータス取得する
 */
export const getStoreLinkedInformation = (
    storeId: string,
 ) => {
      return customInstance<StoreLinkedInformation>(
      {url: `/stores/${storeId}/linked-information`, method: 'GET'
    },
      );
    }
  


export const getGetStoreLinkedInformationKey = (storeId: string,) => [`/stores/${storeId}/linked-information`] as const;


export type GetStoreLinkedInformationQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreLinkedInformation>>>
export type GetStoreLinkedInformationQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary 媒体連携ステータス取得する
 */
export const useGetStoreLinkedInformation = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  storeId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreLinkedInformation>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(storeId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreLinkedInformationKey(storeId) : null);
  const swrFn = () => getStoreLinkedInformation(storeId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗一覧を取得する
 * @summary 店舗一覧を取得する
 */
export const getStores = (
    params?: GetStoresParams,
 ) => {
      return customInstance<StoreList>(
      {url: `/stores`, method: 'GET',
        params
    },
      );
    }
  


export const getGetStoresKey = (params?: GetStoresParams,) => [`/stores`, ...(params ? [params]: [])] as const;


export type GetStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getStores>>>
export type GetStoresQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary 店舗一覧を取得する
 */
export const useGetStores = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  params?: GetStoresParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStores>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoresKey(params) : null);
  const swrFn = () => getStores(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 媒体毎のアカウントエラーを取得する
 * @summary アカウントエラーを取得する
 */
export const getStoreErrorStatuses = (
    params?: GetStoreErrorStatusesParams,
 ) => {
      return customInstance<GetStoreErrorStatuses200>(
      {url: `/stores/error-statuses`, method: 'GET',
        params
    },
      );
    }
  


export const getGetStoreErrorStatusesKey = (params?: GetStoreErrorStatusesParams,) => [`/stores/error-statuses`, ...(params ? [params]: [])] as const;


export type GetStoreErrorStatusesQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreErrorStatuses>>>
export type GetStoreErrorStatusesQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse

/**
 * @summary アカウントエラーを取得する
 */
export const useGetStoreErrorStatuses = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse>(
  params?: GetStoreErrorStatusesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreErrorStatuses>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreErrorStatusesKey(params) : null);
  const swrFn = () => getStoreErrorStatuses(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * @summary 店舗一括登録用CSVのS3 PresignedURLを取得する
 */
export const getStoreBulkInsertFormatCsvDownloadUrl = (
    
 ) => {
      return customInstance<FileDownloadLink>(
      {url: `/stores/bulk-insert-format/download-csv-url`, method: 'GET'
    },
      );
    }
  


export const getGetStoreBulkInsertFormatCsvDownloadUrlKey = () => [`/stores/bulk-insert-format/download-csv-url`] as const;


export type GetStoreBulkInsertFormatCsvDownloadUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreBulkInsertFormatCsvDownloadUrl>>>
export type GetStoreBulkInsertFormatCsvDownloadUrlQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse

/**
 * @summary 店舗一括登録用CSVのS3 PresignedURLを取得する
 */
export const useGetStoreBulkInsertFormatCsvDownloadUrl = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse>(
   options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getStoreBulkInsertFormatCsvDownloadUrl>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetStoreBulkInsertFormatCsvDownloadUrlKey() : null);
  const swrFn = () => getStoreBulkInsertFormatCsvDownloadUrl();

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

import { HelpText } from '@/types/store';
import { atom } from 'jotai';

export const storeIdAtom = atom<string>('-1');

export const storeAtom = atom<{
  id: string;
  name: string;
  companyId: string;
}>({
  id: '-1',
  name: '',
  companyId: '-1',
});

export const helpTextAtom = atom<HelpText | undefined>({ title: '', text: [] });

export const isGbpLinkedAtom = atom<boolean>(false);

export const isAppleLinkedAtom = atom<boolean>(false);

export const isYahooLinkedAtom = atom<boolean>(false);

export type facebookAtomProps = {
  isReadyToLink: boolean;
  isLinked: boolean;
  accessToken: string | null;
  userId: string | null;
};

export const facebookAtom = atom<facebookAtomProps>({
  isReadyToLink: false,
  isLinked: false,
  accessToken: null,
  userId: null,
});

export type instagramAtomProps = {
  isReadyToLink: boolean;
  isLinked: boolean;
  accessToken: string | null;
};

export const instagramAtom = atom<instagramAtomProps>({
  isReadyToLink: false,
  isLinked: false,
  accessToken: null,
});

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * サービス価格タイプ
* 0:価格情報はありません,
* 1:フリー,
* 2:固定
 */
export type GbpServiceItemServicePriceType = typeof GbpServiceItemServicePriceType[keyof typeof GbpServiceItemServicePriceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GbpServiceItemServicePriceType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

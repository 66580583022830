/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 営業時間種別
* close: 定休日・休業日
* openHours: 時間指定
* open24h: 24時間営業

 */
export type StoreAdditionalBusinessDayBusinessHourType = typeof StoreAdditionalBusinessDayBusinessHourType[keyof typeof StoreAdditionalBusinessDayBusinessHourType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoreAdditionalBusinessDayBusinessHourType = {
  close: 'close',
  openHours: 'openHours',
  open24h: 'open24h',
} as const;

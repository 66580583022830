/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type GetReviewsHasReply = typeof GetReviewsHasReply[keyof typeof GetReviewsHasReply];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReviewsHasReply = {
  YES: 'YES',
  NO: 'NO',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  Brands,
  Companies,
  CompanyName,
  ErrorForbiddenResponse,
  ErrorInternalServerResponse,
  ErrorNotFoundResponse,
  ErrorUnauthenticatedResponse,
  ErrorValidationResponse,
  FileDownloadLink,
  GetCompaniesParams,
  GetCompanyUploadS3UrlParams,
  Groups
} from '../schema'
import { customInstance } from '../mutator/custom-instance';



  
  /**
 * グローバルフィルターに表示する会社一覧を取得するAPI。
ログインユーザーが参照できる会社のみ取得。

会社名での検索が可能。未指定の場合は全件取得。
companyIdをクエリパラメーターに指定した場合はその会社を最上位の会社として会社一覧を取得。

 * @summary 会社一覧を取得する
 */
export const getCompanies = (
    params?: GetCompaniesParams,
 ) => {
      return customInstance<Companies>(
      {url: `/companies`, method: 'GET',
        params
    },
      );
    }
  


export const getGetCompaniesKey = (params?: GetCompaniesParams,) => [`/companies`, ...(params ? [params]: [])] as const;


export type GetCompaniesQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanies>>>
export type GetCompaniesQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse

/**
 * @summary 会社一覧を取得する
 */
export const useGetCompanies = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorInternalServerResponse>(
  params?: GetCompaniesParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCompanies>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCompaniesKey(params) : null);
  const swrFn = () => getCompanies(params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 会社名称が取得できること
 * @summary 会社名称を取得する
 */
export const getCompanyName = (
    companyId: string,
 ) => {
      return customInstance<CompanyName>(
      {url: `/companies/${companyId}/name`, method: 'GET'
    },
      );
    }
  


export const getGetCompanyNameKey = (companyId: string,) => [`/companies/${companyId}/name`] as const;


export type GetCompanyNameQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyName>>>
export type GetCompanyNameQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 会社名称を取得する
 */
export const useGetCompanyName = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  companyId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCompanyName>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCompanyNameKey(companyId) : null);
  const swrFn = () => getCompanyName(companyId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * S3オブジェクトの PresignedURL が取得できる
 * @summary 会社に関連するS3 PresignedURL を取得する
 */
export const getCompanyUploadS3Url = (
    companyId: string,
    params: GetCompanyUploadS3UrlParams,
 ) => {
      return customInstance<FileDownloadLink>(
      {url: `/companies/${companyId}/upload-s3-url`, method: 'GET',
        params
    },
      );
    }
  


export const getGetCompanyUploadS3UrlKey = (companyId: string,
    params: GetCompanyUploadS3UrlParams,) => [`/companies/${companyId}/upload-s3-url`, ...(params ? [params]: [])] as const;


export type GetCompanyUploadS3UrlQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyUploadS3Url>>>
export type GetCompanyUploadS3UrlQueryError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 会社に関連するS3 PresignedURL を取得する
 */
export const useGetCompanyUploadS3Url = <TError = ErrorValidationResponse | ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  companyId: string,
    params: GetCompanyUploadS3UrlParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCompanyUploadS3Url>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCompanyUploadS3UrlKey(companyId,params) : null);
  const swrFn = () => getCompanyUploadS3Url(companyId,params, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * 店舗グループリストを取得する
 * @summary 店舗グループリストを取得する
 */
export const getCompanyGroups = (
    companyId: string,
 ) => {
      return customInstance<Groups>(
      {url: `/companies/${companyId}/groups`, method: 'GET'
    },
      );
    }
  


export const getGetCompanyGroupsKey = (companyId: string,) => [`/companies/${companyId}/groups`] as const;


export type GetCompanyGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyGroups>>>
export type GetCompanyGroupsQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary 店舗グループリストを取得する
 */
export const useGetCompanyGroups = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  companyId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCompanyGroups>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCompanyGroupsKey(companyId) : null);
  const swrFn = () => getCompanyGroups(companyId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}
/**
 * パラメータの会社IDをもとにブランド一覧を取得するAPI
 * @summary ブランド一覧を取得する
 */
export const getCompanyBrands = (
    companyId: string,
 ) => {
      return customInstance<Brands>(
      {url: `/companies/${companyId}/brands`, method: 'GET'
    },
      );
    }
  


export const getGetCompanyBrandsKey = (companyId: string,) => [`/companies/${companyId}/brands`] as const;


export type GetCompanyBrandsQueryResult = NonNullable<Awaited<ReturnType<typeof getCompanyBrands>>>
export type GetCompanyBrandsQueryError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse

/**
 * @summary ブランド一覧を取得する
 */
export const useGetCompanyBrands = <TError = ErrorUnauthenticatedResponse | ErrorForbiddenResponse | ErrorNotFoundResponse | ErrorInternalServerResponse>(
  companyId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getCompanyBrands>>, TError> & { swrKey?: Key, enabled?: boolean },  }
) => {
  const {swr: swrOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyId)
  const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetCompanyBrandsKey(companyId) : null);
  const swrFn = () => getCompanyBrands(companyId, );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

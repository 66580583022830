import { Button, type ButtonProps } from '@chakra-ui/react';
import { Icon, IconName } from '../Icon/Icon';

type TertiaryButtonProps = {
  label: string;
  iconName: IconName;
} & ButtonProps;

export function TertiaryButton({
  label,
  iconName,
  onClick,
  ...rest
}: TertiaryButtonProps) {
  return (
    <Button
      size="sm"
      variant="tertiary"
      leftIcon={<Icon name={iconName} size="sm" color="blue.500" />}
      onClick={onClick}
      {...rest}
    >
      {label}
    </Button>
  );
}

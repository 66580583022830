/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

/**
 * 利用ステータス
* INVITING: 招待中
* AVAILABLE: 利用中

 */
export type UserListItemStatus = typeof UserListItemStatus[keyof typeof UserListItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserListItemStatus = {
  INVITING: 'INVITING',
  AVAILABLE: 'AVAILABLE',
} as const;

import { BusinessHour } from '@/lib/api/schema';
import { addDays, parse } from 'date-fns';

export function validateHours(hours: BusinessHour[]) {
  // Optionalなので空の場合はtrue
  if (!hours) return true;

  for (let i = 0; i < hours.length; i++) {
    const { openTime, closeTime } = hours[i];

    // どちらかが空の場合
    if (!openTime || !closeTime) return false;

    // 同一の値の場合
    if (openTime === closeTime) {
      return '開始時間と終了時間には、異なる時間を設定してください。';
    }
  }

  // 時間の重複チェック
  const parseHours = structuredClone(hours)
    .map((hour) => {
      const open = parse(
        hour.openTime as string,
        'HH:mm',
        new Date(),
      ).getTime();
      const close = parse(
        hour.closeTime as string,
        'HH:mm',
        new Date(),
      ).getTime();
      return {
        openTime: open,
        closeTime:
          open > close
            ? addDays(
                parse(hour.closeTime as string, 'HH:mm', new Date()),
                1,
              ).getTime()
            : close,
      };
    })
    .sort((a, b) => a.openTime - b.openTime);
  for (let i = 0; i < parseHours.length - 1; i++) {
    if (parseHours[i].closeTime > parseHours[i + 1].openTime) {
      return '時間の重複があります。';
    }
  }
  return true;
}

import { Text, Box } from '@chakra-ui/react';

export type ModalButtonsProps = {
  primary?: { label?: string; isDisabled?: boolean; onClick: () => void };
  danger?: { label?: string; isDisabled?: boolean; onClick: () => void };
  secondary?: { label?: string; isDisabled?: boolean; onClick: () => void };
  text?: { label: string; isDisabled?: boolean; onClick: () => void };
};

type SearchBoxProps = {
  word: string;
  onClickSearchBox: (word: string) => void;
};

export function SearchBox({ word, onClickSearchBox }: SearchBoxProps) {
  return (
    <Box
      w="100%"
      h="36px"
      zIndex="10"
      position="absolute"
      mt="2px"
      bgColor="white"
      border="1px"
      borderColor="gray.200"
      borderRadius="4px"
      padding="8px 12px 8px 12px"
      onClick={() => onClickSearchBox(word)}
    >
      <Text cursor="pointer">
        {word.length > 25 ? `${word.slice(0, 25)}...` : word}
        <Text color="gray.300" as="span">
          で検索
        </Text>
      </Text>
    </Box>
  );
}

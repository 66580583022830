/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type OrderParameter = typeof OrderParameter[keyof typeof OrderParameter];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderParameter = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

'use client';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Labels } from '@/assets/i18n/ja';
import { RemoveButton, TertiaryButton } from '@/components/Button';
import { DatePicker } from '@/components/DatePicker';
import { EditConfirmationDialog } from '@/components/Dialog';
import { SelectTime } from '@/components/Select';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { formatDate } from 'date-fns';
import { ErrorMessage } from '@hookform/error-message';
import {
  BusinessHour,
  StoreAdditionalBusinessDay,
  StoreBusinessDayBusinessHourType,
} from '@/lib/api/schema';
import { validateHours } from '@/utils/validation';

type BulkAddDatesForm = {
  dates: Date[];
  businessHourType: StoreBusinessDayBusinessHourType;
  businessHours: BusinessHour[];
};

export function BusinessDateBulkAddDialog(props: {
  onOk: (vals: StoreAdditionalBusinessDay[]) => void;
}) {
  const { onOk } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { control, getValues, reset, formState } = useForm<BulkAddDatesForm>({
    defaultValues: {
      dates: [],
      businessHourType: 'close',
      businessHours: [],
    },
    mode: 'onChange',
  });
  const { isDirty, isValid, errors } = formState;
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'businessHours',
    rules: {
      validate: (hours: BusinessHour[], formValues: BulkAddDatesForm) => {
        if (formValues.businessHourType !== 'openHours') return true;
        return validateHours(hours);
      },
    },
  });

  useEffect(() => {
    if (isOpen) reset();
  }, [isOpen, reset]);

  function handleClose() {
    return isDirty ? setIsConfirmationOpen(true) : onClose();
  }

  function handleOk() {
    const { dates, businessHourType, businessHours } = getValues();
    const values: StoreAdditionalBusinessDay[] = dates.sort().map((d) => ({
      additionalDate: formatDate(d, 'yyyy-MM-dd'),
      businessHourType,
      businessHours:
        businessHourType !== 'openHours'
          ? null
          : businessHours.sort((a, b) => (a.openTime! > b.openTime! ? 1 : -1)),
    }));
    onOk(values);
    onClose();
  }
  // console.log('errors', errors);

  return (
    <>
      <TertiaryButton
        label={Labels.button.bulkAddDates}
        iconName="calendar"
        onClick={onOpen}
      />

      {isConfirmationOpen && (
        <EditConfirmationDialog
          isValid={isValid}
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onClickSecondary={onClose}
          onClickPrimary={handleOk}
        />
      )}

      {isOpen && (
        <Modal
          variant="fixedHeight"
          size="md"
          isOpen={isOpen}
          onClose={handleClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>日付をまとめて追加</ModalHeader>
            <ModalBody>
              <Controller
                control={control}
                name="dates"
                rules={{ required: '日付けを選択して下さい。' }}
                render={({ field: { value, onChange } }) => (
                  <Box textAlign="center">
                    <DatePicker
                      inline
                      disabledKeyboardNavigation
                      minDate={new Date()}
                      monthsShown={2}
                      // TODO: update @types/react-datepicker when released
                      // types が本体バージョンに追いついていないので属性が無くリントエラー
                      // https://reactdatepicker.com/#example-select-multiple-dates
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      selectsMultiple
                      selectedDates={value}
                      onChange={onChange}
                    />
                    {value.length === 0 && (
                      <Text variant="invalid">日付けを選択して下さい。</Text>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="businessHourType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Flex align="start" gap={4}>
                    <Switch
                      py={1}
                      isChecked={value !== 'close'}
                      onChange={(e) => {
                        const v = e.target.checked ? 'openHours' : 'close';
                        if (v === 'openHours' && fields.length === 0) {
                          append({ openTime: null, closeTime: null });
                        }
                        onChange(v);
                      }}
                    >
                      {value === 'close'
                        ? Labels.dateType.close
                        : Labels.dateType.open}
                    </Switch>
                    <Flex direction="column" gap={2} align="flex-start">
                      {value !== 'close' && (
                        <RadioGroup
                          value={value}
                          onChange={(v) => {
                            if (v === 'openHours' && fields.length === 0) {
                              append({ openTime: null, closeTime: null });
                            }
                            onChange(v);
                          }}
                        >
                          <Flex align="center" gap={4}>
                            <Radio value={'openHours'}>
                              {Labels.hourType.openHours}
                            </Radio>
                            <Radio value={'open24h'}>
                              {Labels.hourType.open24h}
                            </Radio>
                          </Flex>
                        </RadioGroup>
                      )}
                      {value === 'openHours' &&
                        fields.map(({ id, openTime, closeTime }, index) => {
                          let errorMessage: string | null = null;
                          if (!openTime || !closeTime) {
                            errorMessage = '時間を入力して下さい。';
                          }
                          return (
                            <Fragment key={id}>
                              <Flex align="center" gap={2}>
                                <SelectTime
                                  placeholder={Labels.hour.openTime}
                                  value={openTime}
                                  onChange={(val) => {
                                    update(index, { openTime: val, closeTime });
                                  }}
                                />
                                {' - '}
                                <SelectTime
                                  placeholder={Labels.hour.closeTime}
                                  value={closeTime}
                                  onChange={(val) => {
                                    update(index, { openTime, closeTime: val });
                                  }}
                                />
                                <RemoveButton
                                  onClick={() => {
                                    remove(index);
                                    if (fields.length < 2) onChange('close');
                                  }}
                                />
                              </Flex>
                              {errorMessage && (
                                <Text variant="invalid">{errorMessage}</Text>
                              )}
                            </Fragment>
                          );
                        })}
                      {value === 'openHours' && !isValid && (
                        <ErrorMessage
                          name="businessHours.root"
                          errors={errors}
                          render={({ message }) => (
                            <Text variant="invalid">{message}</Text>
                          )}
                        />
                      )}
                      {value === 'openHours' && fields.length < 3 && (
                        <TertiaryButton
                          label={Labels.button.addDates}
                          iconName="addCircle"
                          onClick={() =>
                            append({ openTime: null, closeTime: null })
                          }
                        />
                      )}
                    </Flex>
                  </Flex>
                )}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="secondary" onClick={handleClose}>
                {Labels.button.cancel}
              </Button>
              <Button isDisabled={!isValid} onClick={handleOk}>
                {Labels.button.done}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type EditReviewReplyTargetReply = typeof EditReviewReplyTargetReply[keyof typeof EditReviewReplyTargetReply];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EditReviewReplyTargetReply = {
  public: 'public',
  pending: 'pending',
} as const;

/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * リニューアルCanly API Document
 * リニューアルCanly API Document
 * OpenAPI spec version: 1.0
 */

export type ReviewReplyStatus = typeof ReviewReplyStatus[keyof typeof ReviewReplyStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewReplyStatus = {
  NOT_REPLIED: 'NOT_REPLIED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PREPARING: 'PREPARING',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED',
  ERROR: 'ERROR',
} as const;
